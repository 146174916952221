/*============================================================================
  TYPOGRAPHY
==============================================================================*/

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2017 */

@font-face {
  font-family: "brownbold";
  src: url("../fonts/brown/brown-bold-webfont.eot");
  src: url("../fonts/brown/brown-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/brown/brown-bold-webfont.woff2") format("woff2"),
    url("../fonts/brown/brown-bold-webfont.woff") format("woff"),
    url("../fonts/brown/brown-bold-webfont.ttf") format("truetype"),
    url("../fonts/brown/brown-bold-webfont.svg#brownbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brownlight";
  src: url("../fonts/brown/brown-light-webfont.eot");
  src: url("../fonts/brown/brown-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/brown/brown-light-webfont.woff2") format("woff2"),
    url("../fonts/brown/brown-light-webfont.woff") format("woff"),
    url("../fonts/brown/brown-light-webfont.ttf") format("truetype"),
    url("../fonts/brown/brown-light-webfont.svg#brownlight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brownregular";
  src: url("../fonts/brown/brown-regular-webfont.eot");
  src: url("../fonts/brown/brown-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/brown/brown-regular-webfont.woff2") format("woff2"),
    url("../fonts/brown/brown-regular-webfont.woff") format("woff"),
    url("../fonts/brown/brown-regular-webfont.ttf") format("truetype"),
    url("../fonts/brown/brown-regular-webfont.svg#brownregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmanretro";
  src: url("../fonts/harman/harman-retro-webfont.woff2") format("woff2"),
    url("../fonts/harman/harman-retro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmanretro";
  src: url("../fonts/harman/harman-retro-webfont.woff2") format("woff2"),
    url("../fonts/harman/harman-retro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmansimple";
  src: url("../fonts/harman/harman-simple-webfont.woff2") format("woff2"),
    url("../fonts/harman/harman-simple-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "northwellregular";
  src: url("../fonts/brown/northwell-webfont.woff2") format("woff2"),
    url("../fonts/brown/northwell-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*
 font-family: 'Abhaya Libre', serif;
font-family: 'Cormorant', serif;
font-family: 'Work Sans', sans-serif;
 */

// Font Stacks
@mixin default-font($size: 16, $weight: 400, $lineHeight: 1.6em) {
  font-family: "Work Sans", Helvetica, Arial, Verdana, sans-serif;
  @include fs(15);
  line-height: $lineHeight;
  font-weight: $weight;
  @media #{$medium} {
    @include fs($size);
  }
}

@mixin display-font() {
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin abhaya() {
  font-family: "Abhaya Libre", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 100;
}

@mixin harmansimple() {
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin brown() {
  font-family: "brownregular", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin cormorant() {
  font-family: "Cormorant", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin worksans() {
  font-family: "Work Sans", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin harmanretro() {
  font-family: "harmanretro", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@mixin northwell() {
  font-family: "northwellregular", serif;
}

// Body

p {
  line-height: 1.7em;
  margin-bottom: 1.7em;
}

strong,
.strong {
  font-weight: 600;
  font-size: inherit;
}

em,
.italic {
  @include cormorant;
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit;
  font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: none;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:focus,
  &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

a:not([href]) {
  color: $black;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

// Headings

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @include display-font;
  @include optimize-font;
  color: $black;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: $black;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &.strong.strong {
    font-weight: 600;
  }
}

h1,
.h1 {
  //color: blue;

  @include fluid-type(font-size, $mediumSize, $largerSize, 28px, 45px);
  @include fluid-type(line-height, $mediumSize, $largerSize, 32px, 55px);
  @include harmansimple;
}

h2,
.h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 28px, 45px);
  @include fluid-type(line-height, $mediumSize, $largerSize, 32px, 50px);
  @include harmansimple;
}

h3,
.h3 {
  @include harmansimple;
  @include fluid-type(font-size, $mediumSize, $largeSize, 20px, 30px);
  @include fluid-type(line-height, $mediumSize, $largeSize, 23px, 33px);
}

h4,
.h4 {
  @include fs(15);
  @include worksans;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  display: block;
}

// Lists

ul,
ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #e3e3e3;
      padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled,
nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt,
dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

// Misc
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 50px 0;
}

.about-body {
  blockquote {
    p {
      margin-bottom: 0.5em;
      line-height: 1.2em;
    }
  }
}

blockquote {
  line-height: inherit;
  text-align: left;
  margin-bottom: 1.5em;

  @include fs(20);
  @media #{$medium} {
    @include fs(25);
  }
  @media #{$large} {
    @include fs(30);
  }

  @include harmansimple;
  font-weight: 500;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}

@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot?8ehmvh");
  src: url("../fonts/icons/icons.eot?8ehmvh#iefix") format("embedded-opentype"),
    url("../fonts/icons/icons.ttf?8ehmvh") format("truetype"),
    url("../fonts/icons/icons.woff?8ehmvh") format("woff"),
    url("../fonts/icons/icons.svg?8ehmvh#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin iconfont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  @include iconfont;
}

.icon-arrow-down:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-instagram:before {
  content: "\e911";
}
.icon-long-arrow:before {
  content: "\e912";
}
