/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block;
}

*, *:before, *:after {
  box-sizing: border-box;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

a:focus {
  outline: thin dotted;
}

ol, ul {
  list-style: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

figure {
  margin: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

abbr {
  text-transform: uppercase;
}

time {
  display: block;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0;
  }
}

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0;
  }
}

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.vh.focusable:active, .vh.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full, .grid--g40, .grid--g50, .grid--g80, .grid--g100, .grid--g120, .grid--g160, .blog-slideshow-wrap__item {
  *zoom: 1;
  margin: 0 0 20px -20px;
}

.grid:before, .grid--rev:before, .grid--full:before, .grid--g40:before, .grid--g50:before, .grid--g80:before, .grid--g100:before, .grid--g120:before, .grid--g160:before, .blog-slideshow-wrap__item:before, .grid:after, .grid--rev:after, .grid--full:after, .grid--g40:after, .grid--g50:after, .grid--g80:after, .grid--g100:after, .grid--g120:after, .grid--g160:after, .blog-slideshow-wrap__item:after {
  display: table;
  content: " ";
}

.grid:after, .grid--rev:after, .grid--full:after, .grid--g40:after, .grid--g50:after, .grid--g80:after, .grid--g100:after, .grid--g120:after, .grid--g160:after, .blog-slideshow-wrap__item:after {
  clear: both;
}

ul.grid, ul.grid--rev, ul.grid--full, ul.grid--g40, ul.grid--g50, ul.grid--g80, ul.grid--g100, ul.grid--g120, ul.grid--g160, ul.blog-slideshow-wrap__item,
ul.grid-uniform {
  list-style: none;
  padding: 0;
}

.grid__item {
  float: left;
  padding-left: 20px;
  width: 100%;
}

.grid--rev {
  direction: rtl;
  text-align: left;
}

.grid--rev > .grid__item {
  direction: ltr;
  text-align: left;
  float: right;
}

.grid--full {
  margin-left: 0;
}

.grid--full > .grid__item {
  padding-left: 0;
}

.grid--g40 {
  margin-left: -40px;
}

.grid--g40 > .grid__item {
  padding-left: 40px;
}

.grid--g50 {
  margin-left: -50px;
}

.grid--g50 > .grid__item {
  padding-left: 50px;
}

.grid--g80 {
  margin-left: -40px;
}

@media (min-width: 1200px) {
  .grid--g80 {
    margin-left: -80px;
  }
}

.grid--g80 > .grid__item {
  padding-left: 40px;
}

@media (min-width: 1200px) {
  .grid--g80 > .grid__item {
    padding-left: 80px;
  }
}

.grid--g100 {
  margin-left: -50px;
}

@media (min-width: 1200px) {
  .grid--g100 {
    margin-left: -100px;
  }
}

.grid--g100 > .grid__item {
  padding-left: 50px;
}

@media (min-width: 1200px) {
  .grid--g100 > .grid__item {
    padding-left: 100px;
  }
}

.grid--g120 {
  margin-left: -60px;
}

@media (min-width: 1200px) {
  .grid--g120 {
    margin-left: -120px;
  }
}

.grid--g120 > .grid__item {
  padding-left: 60px;
}

@media (min-width: 1200px) {
  .grid--g120 > .grid__item {
    padding-left: 120px;
  }
}

.grid--g160 {
  margin-left: -40px;
}

@media (min-width: 768px) {
  .grid--g160 {
    margin-left: -80px;
  }
}

@media (min-width: 1200px) {
  .grid--g160 {
    margin-left: -160px;
  }
}

.grid--g160 > .grid__item {
  padding-left: 40px;
}

@media (min-width: 768px) {
  .grid--g160 > .grid__item {
    padding-left: 80px;
  }
}

@media (min-width: 1200px) {
  .grid--g160 > .grid__item {
    padding-left: 160px;
  }
}

.grid--justify-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.one-whole {
  width: 100%;
}

.one-half {
  width: 50%;
}

.one-third {
  width: 33.333%;
}

.two-thirds {
  width: 66.666%;
}

.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

.one-sixth {
  width: 16.666%;
}

.two-sixths {
  width: 33.333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.666%;
}

.five-sixths {
  width: 83.333%;
}

.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

.one-tenth {
  width: 10%;
}

.two-tenths {
  width: 20%;
}

.three-tenths {
  width: 30%;
}

.four-tenths {
  width: 40%;
}

.five-tenths {
  width: 50%;
}

.six-tenths {
  width: 60%;
}

.seven-tenths {
  width: 70%;
}

.eight-tenths {
  width: 80%;
}

.nine-tenths {
  width: 90%;
}

.one-twelfth {
  width: 8.333%;
}

.two-twelfths {
  width: 16.666%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.333%;
}

.five-twelfths {
  width: 41.666%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.333%;
}

.eight-twelfths {
  width: 66.666%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.333%;
}

.eleven-twelfths {
  width: 91.666%;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

@media only screen and (min-width: 480px) {
  .small--one-whole {
    width: 100%;
  }
  .small--one-half {
    width: 50%;
  }
  .small--one-third {
    width: 33.333%;
  }
  .small--two-thirds {
    width: 66.666%;
  }
  .small--one-quarter {
    width: 25%;
  }
  .small--two-quarters {
    width: 50%;
  }
  .small--three-quarters {
    width: 75%;
  }
  .small--one-fifth {
    width: 20%;
  }
  .small--two-fifths {
    width: 40%;
  }
  .small--three-fifths {
    width: 60%;
  }
  .small--four-fifths {
    width: 80%;
  }
  .small--one-sixth {
    width: 16.666%;
  }
  .small--two-sixths {
    width: 33.333%;
  }
  .small--three-sixths {
    width: 50%;
  }
  .small--four-sixths {
    width: 66.666%;
  }
  .small--five-sixths {
    width: 83.333%;
  }
  .small--one-eighth {
    width: 12.5%;
  }
  .small--two-eighths {
    width: 25%;
  }
  .small--three-eighths {
    width: 37.5%;
  }
  .small--four-eighths {
    width: 50%;
  }
  .small--five-eighths {
    width: 62.5%;
  }
  .small--six-eighths {
    width: 75%;
  }
  .small--seven-eighths {
    width: 87.5%;
  }
  .small--one-tenth {
    width: 10%;
  }
  .small--two-tenths {
    width: 20%;
  }
  .small--three-tenths {
    width: 30%;
  }
  .small--four-tenths {
    width: 40%;
  }
  .small--five-tenths {
    width: 50%;
  }
  .small--six-tenths {
    width: 60%;
  }
  .small--seven-tenths {
    width: 70%;
  }
  .small--eight-tenths {
    width: 80%;
  }
  .small--nine-tenths {
    width: 90%;
  }
  .small--one-twelfth {
    width: 8.333%;
  }
  .small--two-twelfths {
    width: 16.666%;
  }
  .small--three-twelfths {
    width: 25%;
  }
  .small--four-twelfths {
    width: 33.333%;
  }
  .small--five-twelfths {
    width: 41.666%;
  }
  .small--six-twelfths {
    width: 50%;
  }
  .small--seven-twelfths {
    width: 58.333%;
  }
  .small--eight-twelfths {
    width: 66.666%;
  }
  .small--nine-twelfths {
    width: 75%;
  }
  .small--ten-twelfths {
    width: 83.333%;
  }
  .small--eleven-twelfths {
    width: 91.666%;
  }
  .small--show {
    display: block !important;
  }
  .small--hide {
    display: none !important;
  }
  .small--text-left {
    text-align: left !important;
  }
  .small--text-right {
    text-align: right !important;
  }
  .small--text-center {
    text-align: center !important;
  }
  .small--left {
    float: left !important;
  }
  .small--right {
    float: right !important;
  }
}

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%;
  }
  .medium--one-half {
    width: 50%;
  }
  .medium--one-third {
    width: 33.333%;
  }
  .medium--two-thirds {
    width: 66.666%;
  }
  .medium--one-quarter {
    width: 25%;
  }
  .medium--two-quarters {
    width: 50%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-fifth {
    width: 20%;
  }
  .medium--two-fifths {
    width: 40%;
  }
  .medium--three-fifths {
    width: 60%;
  }
  .medium--four-fifths {
    width: 80%;
  }
  .medium--one-sixth {
    width: 16.666%;
  }
  .medium--two-sixths {
    width: 33.333%;
  }
  .medium--three-sixths {
    width: 50%;
  }
  .medium--four-sixths {
    width: 66.666%;
  }
  .medium--five-sixths {
    width: 83.333%;
  }
  .medium--one-eighth {
    width: 12.5%;
  }
  .medium--two-eighths {
    width: 25%;
  }
  .medium--three-eighths {
    width: 37.5%;
  }
  .medium--four-eighths {
    width: 50%;
  }
  .medium--five-eighths {
    width: 62.5%;
  }
  .medium--six-eighths {
    width: 75%;
  }
  .medium--seven-eighths {
    width: 87.5%;
  }
  .medium--one-tenth {
    width: 10%;
  }
  .medium--two-tenths {
    width: 20%;
  }
  .medium--three-tenths {
    width: 30%;
  }
  .medium--four-tenths {
    width: 40%;
  }
  .medium--five-tenths {
    width: 50%;
  }
  .medium--six-tenths {
    width: 60%;
  }
  .medium--seven-tenths {
    width: 70%;
  }
  .medium--eight-tenths {
    width: 80%;
  }
  .medium--nine-tenths {
    width: 90%;
  }
  .medium--one-twelfth {
    width: 8.333%;
  }
  .medium--two-twelfths {
    width: 16.666%;
  }
  .medium--three-twelfths {
    width: 25%;
  }
  .medium--four-twelfths {
    width: 33.333%;
  }
  .medium--five-twelfths {
    width: 41.666%;
  }
  .medium--six-twelfths {
    width: 50%;
  }
  .medium--seven-twelfths {
    width: 58.333%;
  }
  .medium--eight-twelfths {
    width: 66.666%;
  }
  .medium--nine-twelfths {
    width: 75%;
  }
  .medium--ten-twelfths {
    width: 83.333%;
  }
  .medium--eleven-twelfths {
    width: 91.666%;
  }
  .medium--show {
    display: block !important;
  }
  .medium--hide {
    display: none !important;
  }
  .medium--text-left {
    text-align: left !important;
  }
  .medium--text-right {
    text-align: right !important;
  }
  .medium--text-center {
    text-align: center !important;
  }
  .medium--left {
    float: left !important;
  }
  .medium--right {
    float: right !important;
  }
}

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%;
  }
  .large--one-half {
    width: 50%;
  }
  .large--one-third {
    width: 33.333%;
  }
  .large--two-thirds {
    width: 66.666%;
  }
  .large--one-quarter {
    width: 25%;
  }
  .large--two-quarters {
    width: 50%;
  }
  .large--three-quarters {
    width: 75%;
  }
  .large--one-fifth {
    width: 20%;
  }
  .large--two-fifths {
    width: 40%;
  }
  .large--three-fifths {
    width: 60%;
  }
  .large--four-fifths {
    width: 80%;
  }
  .large--one-sixth {
    width: 16.666%;
  }
  .large--two-sixths {
    width: 33.333%;
  }
  .large--three-sixths {
    width: 50%;
  }
  .large--four-sixths {
    width: 66.666%;
  }
  .large--five-sixths {
    width: 83.333%;
  }
  .large--one-eighth {
    width: 12.5%;
  }
  .large--two-eighths {
    width: 25%;
  }
  .large--three-eighths {
    width: 37.5%;
  }
  .large--four-eighths {
    width: 50%;
  }
  .large--five-eighths {
    width: 62.5%;
  }
  .large--six-eighths {
    width: 75%;
  }
  .large--seven-eighths {
    width: 87.5%;
  }
  .large--one-tenth {
    width: 10%;
  }
  .large--two-tenths {
    width: 20%;
  }
  .large--three-tenths {
    width: 30%;
  }
  .large--four-tenths {
    width: 40%;
  }
  .large--five-tenths {
    width: 50%;
  }
  .large--six-tenths {
    width: 60%;
  }
  .large--seven-tenths {
    width: 70%;
  }
  .large--eight-tenths {
    width: 80%;
  }
  .large--nine-tenths {
    width: 90%;
  }
  .large--one-twelfth {
    width: 8.333%;
  }
  .large--two-twelfths {
    width: 16.666%;
  }
  .large--three-twelfths {
    width: 25%;
  }
  .large--four-twelfths {
    width: 33.333%;
  }
  .large--five-twelfths {
    width: 41.666%;
  }
  .large--six-twelfths {
    width: 50%;
  }
  .large--seven-twelfths {
    width: 58.333%;
  }
  .large--eight-twelfths {
    width: 66.666%;
  }
  .large--nine-twelfths {
    width: 75%;
  }
  .large--ten-twelfths {
    width: 83.333%;
  }
  .large--eleven-twelfths {
    width: 91.666%;
  }
  .large--show {
    display: block !important;
  }
  .large--hide {
    display: none !important;
  }
  .large--text-left {
    text-align: left !important;
  }
  .large--text-right {
    text-align: right !important;
  }
  .large--text-center {
    text-align: center !important;
  }
  .large--left {
    float: left !important;
  }
  .large--right {
    float: right !important;
  }
}

@media only screen and (min-width: 1200px) {
  .larger--one-whole {
    width: 100%;
  }
  .larger--one-half {
    width: 50%;
  }
  .larger--one-third {
    width: 33.333%;
  }
  .larger--two-thirds {
    width: 66.666%;
  }
  .larger--one-quarter {
    width: 25%;
  }
  .larger--two-quarters {
    width: 50%;
  }
  .larger--three-quarters {
    width: 75%;
  }
  .larger--one-fifth {
    width: 20%;
  }
  .larger--two-fifths {
    width: 40%;
  }
  .larger--three-fifths {
    width: 60%;
  }
  .larger--four-fifths {
    width: 80%;
  }
  .larger--one-sixth {
    width: 16.666%;
  }
  .larger--two-sixths {
    width: 33.333%;
  }
  .larger--three-sixths {
    width: 50%;
  }
  .larger--four-sixths {
    width: 66.666%;
  }
  .larger--five-sixths {
    width: 83.333%;
  }
  .larger--one-eighth {
    width: 12.5%;
  }
  .larger--two-eighths {
    width: 25%;
  }
  .larger--three-eighths {
    width: 37.5%;
  }
  .larger--four-eighths {
    width: 50%;
  }
  .larger--five-eighths {
    width: 62.5%;
  }
  .larger--six-eighths {
    width: 75%;
  }
  .larger--seven-eighths {
    width: 87.5%;
  }
  .larger--one-tenth {
    width: 10%;
  }
  .larger--two-tenths {
    width: 20%;
  }
  .larger--three-tenths {
    width: 30%;
  }
  .larger--four-tenths {
    width: 40%;
  }
  .larger--five-tenths {
    width: 50%;
  }
  .larger--six-tenths {
    width: 60%;
  }
  .larger--seven-tenths {
    width: 70%;
  }
  .larger--eight-tenths {
    width: 80%;
  }
  .larger--nine-tenths {
    width: 90%;
  }
  .larger--one-twelfth {
    width: 8.333%;
  }
  .larger--two-twelfths {
    width: 16.666%;
  }
  .larger--three-twelfths {
    width: 25%;
  }
  .larger--four-twelfths {
    width: 33.333%;
  }
  .larger--five-twelfths {
    width: 41.666%;
  }
  .larger--six-twelfths {
    width: 50%;
  }
  .larger--seven-twelfths {
    width: 58.333%;
  }
  .larger--eight-twelfths {
    width: 66.666%;
  }
  .larger--nine-twelfths {
    width: 75%;
  }
  .larger--ten-twelfths {
    width: 83.333%;
  }
  .larger--eleven-twelfths {
    width: 91.666%;
  }
  .larger--show {
    display: block !important;
  }
  .larger--hide {
    display: none !important;
  }
  .larger--text-left {
    text-align: left !important;
  }
  .larger--text-right {
    text-align: right !important;
  }
  .larger--text-center {
    text-align: center !important;
  }
  .larger--left {
    float: left !important;
  }
  .larger--right {
    float: right !important;
  }
}

[class*="push--"] {
  position: relative;
}

.push--one-whole {
  left: 100%;
  position: relative;
}

.push--one-half {
  left: 50%;
  position: relative;
}

.push--one-third {
  left: 33.333%;
  position: relative;
}

.push--two-thirds {
  left: 66.666%;
  position: relative;
}

.push--one-quarter {
  left: 25%;
  position: relative;
}

.push--two-quarters {
  left: 50%;
  position: relative;
}

.push--three-quarters {
  left: 75%;
  position: relative;
}

.push--one-fifth {
  left: 20%;
  position: relative;
}

.push--two-fifths {
  left: 40%;
  position: relative;
}

.push--three-fifths {
  left: 60%;
  position: relative;
}

.push--four-fifths {
  left: 80%;
  position: relative;
}

.push--one-sixth {
  left: 16.666%;
  position: relative;
}

.push--two-sixths {
  left: 33.333%;
  position: relative;
}

.push--three-sixths {
  left: 50%;
  position: relative;
}

.push--four-sixths {
  left: 66.666%;
  position: relative;
}

.push--five-sixths {
  left: 83.333%;
  position: relative;
}

.push--one-eighth {
  left: 12.5%;
  position: relative;
}

.push--two-eighths {
  left: 25%;
  position: relative;
}

.push--three-eighths {
  left: 37.5%;
  position: relative;
}

.push--four-eighths {
  left: 50%;
  position: relative;
}

.push--five-eighths {
  left: 62.5%;
  position: relative;
}

.push--six-eighths {
  left: 75%;
  position: relative;
}

.push--seven-eighths {
  left: 87.5%;
  position: relative;
}

.push--one-tenth {
  left: 10%;
  position: relative;
}

.push--two-tenths {
  left: 20%;
  position: relative;
}

.push--three-tenths {
  left: 30%;
  position: relative;
}

.push--four-tenths {
  left: 40%;
  position: relative;
}

.push--five-tenths {
  left: 50%;
  position: relative;
}

.push--six-tenths {
  left: 60%;
  position: relative;
}

.push--seven-tenths {
  left: 70%;
  position: relative;
}

.push--eight-tenths {
  left: 80%;
  position: relative;
}

.push--nine-tenths {
  left: 90%;
  position: relative;
}

.push--one-twelfth {
  left: 8.333%;
  position: relative;
}

.push--two-twelfths {
  left: 16.666%;
  position: relative;
}

.push--three-twelfths {
  left: 25%;
  position: relative;
}

.push--four-twelfths {
  left: 33.333%;
  position: relative;
}

.push--five-twelfths {
  left: 41.666%;
  position: relative;
}

.push--six-twelfths {
  left: 50%;
  position: relative;
}

.push--seven-twelfths {
  left: 58.333%;
  position: relative;
}

.push--eight-twelfths {
  left: 66.666%;
  position: relative;
}

.push--nine-twelfths {
  left: 75%;
  position: relative;
}

.push--ten-twelfths {
  left: 83.333%;
  position: relative;
}

.push--eleven-twelfths {
  left: 91.666%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .push--medium--one-whole {
    left: 100%;
    position: relative;
  }
  .push--medium--one-half {
    left: 50%;
    position: relative;
  }
  .push--medium--one-third {
    left: 33.333%;
    position: relative;
  }
  .push--medium--two-thirds {
    left: 66.666%;
    position: relative;
  }
  .push--medium--one-quarter {
    left: 25%;
    position: relative;
  }
  .push--medium--two-quarters {
    left: 50%;
    position: relative;
  }
  .push--medium--three-quarters {
    left: 75%;
    position: relative;
  }
  .push--medium--one-fifth {
    left: 20%;
    position: relative;
  }
  .push--medium--two-fifths {
    left: 40%;
    position: relative;
  }
  .push--medium--three-fifths {
    left: 60%;
    position: relative;
  }
  .push--medium--four-fifths {
    left: 80%;
    position: relative;
  }
  .push--medium--one-sixth {
    left: 16.666%;
    position: relative;
  }
  .push--medium--two-sixths {
    left: 33.333%;
    position: relative;
  }
  .push--medium--three-sixths {
    left: 50%;
    position: relative;
  }
  .push--medium--four-sixths {
    left: 66.666%;
    position: relative;
  }
  .push--medium--five-sixths {
    left: 83.333%;
    position: relative;
  }
  .push--medium--one-eighth {
    left: 12.5%;
    position: relative;
  }
  .push--medium--two-eighths {
    left: 25%;
    position: relative;
  }
  .push--medium--three-eighths {
    left: 37.5%;
    position: relative;
  }
  .push--medium--four-eighths {
    left: 50%;
    position: relative;
  }
  .push--medium--five-eighths {
    left: 62.5%;
    position: relative;
  }
  .push--medium--six-eighths {
    left: 75%;
    position: relative;
  }
  .push--medium--seven-eighths {
    left: 87.5%;
    position: relative;
  }
  .push--medium--one-tenth {
    left: 10%;
    position: relative;
  }
  .push--medium--two-tenths {
    left: 20%;
    position: relative;
  }
  .push--medium--three-tenths {
    left: 30%;
    position: relative;
  }
  .push--medium--four-tenths {
    left: 40%;
    position: relative;
  }
  .push--medium--five-tenths {
    left: 50%;
    position: relative;
  }
  .push--medium--six-tenths {
    left: 60%;
    position: relative;
  }
  .push--medium--seven-tenths {
    left: 70%;
    position: relative;
  }
  .push--medium--eight-tenths {
    left: 80%;
    position: relative;
  }
  .push--medium--nine-tenths {
    left: 90%;
    position: relative;
  }
  .push--medium--one-twelfth {
    left: 8.333%;
    position: relative;
  }
  .push--medium--two-twelfths {
    left: 16.666%;
    position: relative;
  }
  .push--medium--three-twelfths {
    left: 25%;
    position: relative;
  }
  .push--medium--four-twelfths {
    left: 33.333%;
    position: relative;
  }
  .push--medium--five-twelfths {
    left: 41.666%;
    position: relative;
  }
  .push--medium--six-twelfths {
    left: 50%;
    position: relative;
  }
  .push--medium--seven-twelfths {
    left: 58.333%;
    position: relative;
  }
  .push--medium--eight-twelfths {
    left: 66.666%;
    position: relative;
  }
  .push--medium--nine-twelfths {
    left: 75%;
    position: relative;
  }
  .push--medium--ten-twelfths {
    left: 83.333%;
    position: relative;
  }
  .push--medium--eleven-twelfths {
    left: 91.666%;
    position: relative;
  }
}

@media only screen and (min-width: 1024px) {
  .push--large--one-whole {
    left: 100%;
    position: relative;
  }
  .push--large--one-half {
    left: 50%;
    position: relative;
  }
  .push--large--one-third {
    left: 33.333%;
    position: relative;
  }
  .push--large--two-thirds {
    left: 66.666%;
    position: relative;
  }
  .push--large--one-quarter {
    left: 25%;
    position: relative;
  }
  .push--large--two-quarters {
    left: 50%;
    position: relative;
  }
  .push--large--three-quarters {
    left: 75%;
    position: relative;
  }
  .push--large--one-fifth {
    left: 20%;
    position: relative;
  }
  .push--large--two-fifths {
    left: 40%;
    position: relative;
  }
  .push--large--three-fifths {
    left: 60%;
    position: relative;
  }
  .push--large--four-fifths {
    left: 80%;
    position: relative;
  }
  .push--large--one-sixth {
    left: 16.666%;
    position: relative;
  }
  .push--large--two-sixths {
    left: 33.333%;
    position: relative;
  }
  .push--large--three-sixths {
    left: 50%;
    position: relative;
  }
  .push--large--four-sixths {
    left: 66.666%;
    position: relative;
  }
  .push--large--five-sixths {
    left: 83.333%;
    position: relative;
  }
  .push--large--one-eighth {
    left: 12.5%;
    position: relative;
  }
  .push--large--two-eighths {
    left: 25%;
    position: relative;
  }
  .push--large--three-eighths {
    left: 37.5%;
    position: relative;
  }
  .push--large--four-eighths {
    left: 50%;
    position: relative;
  }
  .push--large--five-eighths {
    left: 62.5%;
    position: relative;
  }
  .push--large--six-eighths {
    left: 75%;
    position: relative;
  }
  .push--large--seven-eighths {
    left: 87.5%;
    position: relative;
  }
  .push--large--one-tenth {
    left: 10%;
    position: relative;
  }
  .push--large--two-tenths {
    left: 20%;
    position: relative;
  }
  .push--large--three-tenths {
    left: 30%;
    position: relative;
  }
  .push--large--four-tenths {
    left: 40%;
    position: relative;
  }
  .push--large--five-tenths {
    left: 50%;
    position: relative;
  }
  .push--large--six-tenths {
    left: 60%;
    position: relative;
  }
  .push--large--seven-tenths {
    left: 70%;
    position: relative;
  }
  .push--large--eight-tenths {
    left: 80%;
    position: relative;
  }
  .push--large--nine-tenths {
    left: 90%;
    position: relative;
  }
  .push--large--one-twelfth {
    left: 8.333%;
    position: relative;
  }
  .push--large--two-twelfths {
    left: 16.666%;
    position: relative;
  }
  .push--large--three-twelfths {
    left: 25%;
    position: relative;
  }
  .push--large--four-twelfths {
    left: 33.333%;
    position: relative;
  }
  .push--large--five-twelfths {
    left: 41.666%;
    position: relative;
  }
  .push--large--six-twelfths {
    left: 50%;
    position: relative;
  }
  .push--large--seven-twelfths {
    left: 58.333%;
    position: relative;
  }
  .push--large--eight-twelfths {
    left: 66.666%;
    position: relative;
  }
  .push--large--nine-twelfths {
    left: 75%;
    position: relative;
  }
  .push--large--ten-twelfths {
    left: 83.333%;
    position: relative;
  }
  .push--large--eleven-twelfths {
    left: 91.666%;
    position: relative;
  }
}

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 5, 2017 */
@font-face {
  font-family: "brownbold";
  src: url("../fonts/brown/brown-bold-webfont.eot");
  src: url("../fonts/brown/brown-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/brown/brown-bold-webfont.woff2") format("woff2"), url("../fonts/brown/brown-bold-webfont.woff") format("woff"), url("../fonts/brown/brown-bold-webfont.ttf") format("truetype"), url("../fonts/brown/brown-bold-webfont.svg#brownbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brownlight";
  src: url("../fonts/brown/brown-light-webfont.eot");
  src: url("../fonts/brown/brown-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/brown/brown-light-webfont.woff2") format("woff2"), url("../fonts/brown/brown-light-webfont.woff") format("woff"), url("../fonts/brown/brown-light-webfont.ttf") format("truetype"), url("../fonts/brown/brown-light-webfont.svg#brownlight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brownregular";
  src: url("../fonts/brown/brown-regular-webfont.eot");
  src: url("../fonts/brown/brown-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/brown/brown-regular-webfont.woff2") format("woff2"), url("../fonts/brown/brown-regular-webfont.woff") format("woff"), url("../fonts/brown/brown-regular-webfont.ttf") format("truetype"), url("../fonts/brown/brown-regular-webfont.svg#brownregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmanretro";
  src: url("../fonts/harman/harman-retro-webfont.woff2") format("woff2"), url("../fonts/harman/harman-retro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmanretro";
  src: url("../fonts/harman/harman-retro-webfont.woff2") format("woff2"), url("../fonts/harman/harman-retro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "harmansimple";
  src: url("../fonts/harman/harman-simple-webfont.woff2") format("woff2"), url("../fonts/harman/harman-simple-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "northwellregular";
  src: url("../fonts/brown/northwell-webfont.woff2") format("woff2"), url("../fonts/brown/northwell-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*
 font-family: 'Abhaya Libre', serif;
font-family: 'Cormorant', serif;
font-family: 'Work Sans', sans-serif;
 */
p {
  line-height: 1.7em;
  margin-bottom: 1.7em;
}

strong,
.strong {
  font-weight: 600;
  font-size: inherit;
}

em,
.italic {
  font-family: "Cormorant", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit;
  font-style: inherit;
  color: #000;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: none;
  text-decoration-skip: ink;
}

a:focus, a:active {
  outline: 0;
}

a.tdn {
  text-decoration: none;
}

a.tdn:hover, a.tdn:focus {
  text-decoration: underline;
}

a:not([href]) {
  color: #000;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #000;
  line-height: 1.2em;
  margin: 0 0 0.8em;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  text-decoration: none;
  outline: 0;
  font-weight: inherit;
  color: #000;
}

h1 a:hover, h1 a:focus,
.h1 a:hover,
.h1 a:focus,
h2 a:hover,
h2 a:focus,
.h2 a:hover,
.h2 a:focus,
h3 a:hover,
h3 a:focus,
.h3 a:hover,
.h3 a:focus,
h4 a:hover,
h4 a:focus,
.h4 a:hover,
.h4 a:focus,
h5 a:hover,
h5 a:focus,
.h5 a:hover,
.h5 a:focus,
h6 a:hover,
h6 a:focus,
.h6 a:hover,
.h6 a:focus {
  color: #000;
}

h1.strong.strong,
.h1.strong.strong,
h2.strong.strong,
.h2.strong.strong,
h3.strong.strong,
.h3.strong.strong,
h4.strong.strong,
.h4.strong.strong,
h5.strong.strong,
.h5.strong.strong,
h6.strong.strong,
.h6.strong.strong {
  font-weight: 600;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 32px;
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@media screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: calc(28px + 17 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) {
  h1,
  .h1 {
    line-height: calc(32px + 23 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h1,
  .h1 {
    line-height: 55px;
  }
}

h2,
.h2 {
  font-size: 28px;
  line-height: 32px;
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@media screen and (min-width: 768px) {
  h2,
  .h2 {
    font-size: calc(28px + 17 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) {
  h2,
  .h2 {
    line-height: calc(32px + 18 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  h2,
  .h2 {
    line-height: 50px;
  }
}

h3,
.h3 {
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-size: 20px;
  line-height: 23px;
}

@media screen and (min-width: 768px) {
  h3,
  .h3 {
    font-size: calc(20px + 10 * (100vw - 768px) / 256);
  }
}

@media screen and (min-width: 1024px) {
  h3,
  .h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: 768px) {
  h3,
  .h3 {
    line-height: calc(23px + 10 * (100vw - 768px) / 256);
  }
}

@media screen and (min-width: 1024px) {
  h3,
  .h3 {
    line-height: 33px;
  }
}

h4,
.h4 {
  font-size: 15px;
  font-size: 1.5rem;
  font-family: "Work Sans", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  display: block;
}

ul,
ol {
  margin: 0 0 1.5em 16px;
}

ul li,
ol li {
  line-height: 1.5em;
  padding: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

ul.lined li {
  border-bottom: 1px solid #e3e3e3;
  padding: 5px 0;
}

ul.lined li:first-child {
  padding-top: 0;
}

ul.lined li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

ol {
  list-style: decimal;
}

.unstyled, ul.lined,
nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;
}

.unstyled ul, ul.lined ul,
nav ul ul {
  list-style: none;
}

.unstyled li, ul.lined li,
nav ul li {
  list-style: none;
  list-style-image: none;
  padding: 0;
  background: none;
}

nav ul {
  *zoom: 1;
}

nav ul:before, nav ul:after {
  display: table;
  content: " ";
}

nav ul:after {
  clear: both;
}

dl {
  margin: 0 0 1.5em;
}

dt,
dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #b4b4b4;
  margin: 50px 0;
}

.about-body blockquote p {
  margin-bottom: 0.5em;
  line-height: 1.2em;
}

blockquote {
  line-height: inherit;
  text-align: left;
  margin-bottom: 1.5em;
  font-size: 20px;
  font-size: 2rem;
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
}

@media (min-width: 768px) {
  blockquote {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  blockquote {
    font-size: 30px;
    font-size: 3rem;
  }
}

blockquote p {
  margin-bottom: 0.5em;
}

blockquote cite {
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  blockquote {
    padding-left: 40px;
  }
}

@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot?8ehmvh");
  src: url("../fonts/icons/icons.eot?8ehmvh#iefix") format("embedded-opentype"), url("../fonts/icons/icons.ttf?8ehmvh") format("truetype"), url("../fonts/icons/icons.woff?8ehmvh") format("woff"), url("../fonts/icons/icons.svg?8ehmvh#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e90d";
}

.icon-facebook:before {
  content: "\e90e";
}

.icon-instagram:before {
  content: "\e911";
}

.icon-long-arrow:before {
  content: "\e912";
}

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em;
}

table th, table td {
  padding: 11px 6px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  line-height: 1.5em;
}

table th {
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

label, .label, input, select, textarea {
  font-family: "Work Sans", Helvetica, Arial, Verdana, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
  vertical-align: middle;
  color: #000;
}

@media (min-width: 768px) {
  label, .label, input, select, textarea {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

label, .label {
  display: block;
  margin-bottom: 8px;
  text-transform: lowercase;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px;
  margin-bottom: 2px;
  display: block;
  background: #fff;
  height: 35px;
  line-height: inherit;
  width: 100%;
  border: 1px solid #d5d5d5;
}

@media (min-width: 768px) {
  input[type="text"], input[type="search"], input[type="password"],
  input[type="email"], input[type="tel"], input[type="date"], textarea, select {
    border: none;
    border-bottom: 1px solid #d5d5d5;
  }
}

input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, textarea:focus, select:focus {
  outline: 0;
  border-color: #777;
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 185px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

html.ios-device button:after, html.ios-device input[type="reset"]:after, html.ios-device input[type="submit"]:after, html.ios-device .button:after {
  content: none;
}

button, input[type="reset"], input[type="submit"], .button {
  position: relative;
  display: inline-block;
  clear: both;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 15px 25px;
  margin: 10px 0 0;
  background-color: transparent;
  text-decoration: none;
  color: #000;
  border-radius: 0;
  font-weight: 500;
  border: 1px solid #000;
}

@media (min-width: 768px) {
  button, input[type="reset"], input[type="submit"], .button {
    padding: 15px;
  }
}

@media (max-width: 766px) {
  button.mobile--full-width, input[type="reset"].mobile--full-width, input[type="submit"].mobile--full-width, .button.mobile--full-width {
    width: 100%;
    text-align: center;
  }
}

button:after, input[type="reset"]:after, input[type="submit"]:after, .button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #000;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  button, input[type="reset"], input[type="submit"], .button {
    padding: 15px 50px;
  }
}

button i, input[type="reset"] i, input[type="submit"] i, .button i {
  margin: -5px 6px 0 5px;
}

button.button--large, input[type="reset"].button--large, input[type="submit"].button--large, .button.button--large {
  padding: 15px 25px;
}

@media (min-width: 768px) {
  button.button--large, input[type="reset"].button--large, input[type="submit"].button--large, .button.button--large {
    padding: 30px 50px;
  }
}

@media (min-width: 1200px) {
  button.button--large, input[type="reset"].button--large, input[type="submit"].button--large, .button.button--large {
    padding: 30px 70px;
  }
}

button.button--xlarge, input[type="reset"].button--xlarge, input[type="submit"].button--xlarge, .button.button--xlarge {
  padding: 15px 25px;
}

@media (min-width: 768px) {
  button.button--xlarge, input[type="reset"].button--xlarge, input[type="submit"].button--xlarge, .button.button--xlarge {
    padding: 20px 30px;
  }
}

@media (min-width: 1024px) {
  button.button--xlarge, input[type="reset"].button--xlarge, input[type="submit"].button--xlarge, .button.button--xlarge {
    padding: 20px 50px;
  }
}

@media (min-width: 1200px) {
  button.button--xlarge, input[type="reset"].button--xlarge, input[type="submit"].button--xlarge, .button.button--xlarge {
    padding: 25px 75px;
  }
}

@media (min-width: 1680px) {
  button.button--xlarge, input[type="reset"].button--xlarge, input[type="submit"].button--xlarge, .button.button--xlarge {
    padding: 30px 100px;
  }
}

button:hover, button:focus, button:active, button.active, input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="reset"].active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, input[type="submit"].active, .button:hover, .button:focus, .button:active, .button.active {
  color: #000;
}

button:hover:after, button:focus:after, button:active:after, button.active:after, input[type="reset"]:hover:after, input[type="reset"]:focus:after, input[type="reset"]:active:after, input[type="reset"].active:after, input[type="submit"]:hover:after, input[type="submit"]:focus:after, input[type="submit"]:active:after, input[type="submit"].active:after, .button:hover:after, .button:focus:after, .button:active:after, .button.active:after {
  width: 100%;
}

button:active, input[type="reset"]:active, input[type="submit"]:active, .button:active {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
  border: 0;
}

@media (max-width: 766px) {
  button.small--link-style, input[type="reset"].small--link-style, input[type="submit"].small--link-style, .button.small--link-style {
    border: none;
    text-decoration: underline;
    padding: 0;
    background-color: none;
  }
  button.small--link-style:after, input[type="reset"].small--link-style:after, input[type="submit"].small--link-style:after, .button.small--link-style:after {
    content: none;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit;
  box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1);
}

::placeholder {
  color: #999;
  font-style: italic;
}

::-ms-clear {
  display: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-search-results-button {
  -webkit-appearance: none;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

form .row {
  margin-bottom: 0;
}

fieldset {
  width: auto;
}

fieldset p, fieldset ul, fieldset li {
  *zoom: 1;
}

fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
  display: table;
  content: " ";
}

fieldset p:after, fieldset ul:after, fieldset li:after {
  clear: both;
}

fieldset ul {
  margin-left: -20px;
}

fieldset ul li {
  list-style: none;
  background: none;
  list-style-image: none;
  margin: 0 0 10px;
  float: left;
  width: 100%;
  padding: 0 0 0 20px;
}

fieldset ul li.error input {
  border-color: #f1062c;
}

fieldset ul li.space {
  margin-top: 25px;
}

@media (min-width: 768px) {
  fieldset ul li.one-half {
    width: 50%;
  }
  fieldset ul li.one-fourth {
    width: 25%;
  }
}

.buttons {
  *zoom: 1;
}

.buttons:before, .buttons:after {
  display: table;
  content: " ";
}

.buttons:after {
  clear: both;
}

.buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
  float: left;
  clear: none;
  margin-right: 15px;
}

.buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block;
}

.floating-label--container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.floating-label--container label {
  font-size: 14px;
  font-size: 1.4rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  white-space: nowrap;
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.floating-label--container label.active {
  display: block;
  visibility: visible;
  opacity: 1;
  font-size: 12px;
  font-size: 1.2rem;
  transform: translate(1em, 0.05em);
}

.floating-label--container input {
  height: 45px;
}

.floating-label--container input.active {
  padding-top: 20px;
}

.floating-label--container.floating-label--container_above {
  margin-top: 1.4em;
}

.floating-label--container.floating-label--container_above label.active {
  transform: translate(0em, -2em);
}

.floating-label--container.floating-label--container_above input {
  height: 35px;
}

.floating-label--container.floating-label--container_above input.active {
  padding-top: 6px;
}

.floating-label--container.floating-label--container_error input {
  border: 1px solid red;
}

.grecaptcha-badge {
  display: none;
}

.formError {
  z-index: 990;
}

.formError .formErrorContent {
  z-index: 991;
}

.formError .formErrorArrow {
  z-index: 996;
}

.formErrorInsideDialog.formError {
  z-index: 5000;
}

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001;
}

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006;
}

.inputContainer {
  position: relative;
  float: left;
}

.formError, .formError * {
  box-sizing: content-box;
}

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -128px;
}

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none;
}

.formError .formErrorContent {
  background: #f1062c;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #f1062c;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px;
}

.formError .formErrorArrow:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  border-top-color: #f1062c;
}

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px;
}

.formError .formErrorArrow div {
  display: none;
}

.md-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  z-index: 999999;
  min-width: 320px;
  height: auto;
  visibility: hidden;
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  text-align: left;
  font-size: 18px;
}

@media (max-width: 766px) {
  .md-modal {
    display: none;
  }
}

@media (min-width: 1024px) {
  .md-modal {
    width: 940px;
  }
}

.md-modal.mobile {
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.md-modal.md-cover, .md-modal.md-cover-dynamic {
  top: 0;
  left: 0;
  width: 100%;
  transform: none;
}

.md-modal.md-cover .md-content, .md-modal.md-cover-dynamic .md-content {
  height: 100%;
  height: 100vh;
  overflow-y: scroll;
  transform: scale(0.9);
  backface-visibility: hidden;
  padding: 20px 25px;
}

.md-modal.md-cover .md-close, .md-modal.md-cover-dynamic .md-close {
  position: fixed;
  z-index: 1000;
  top: 25px;
  right: 25px;
  opacity: 0;
  transform: translateY(20%);
  padding: 0 5px;
}

.md-modal.md-cover.md-show .md-close, .md-modal.md-show.md-cover-dynamic .md-close {
  opacity: 1;
  transform: translateY(0);
}

.md-modal.md-cover.md-show .md-close .text, .md-modal.md-show.md-cover-dynamic .md-close .text {
  color: #000;
}

.md-modal.md-cover.md-show .md-close:hover .icon-close, .md-modal.md-show.md-cover-dynamic .md-close:hover .icon-close, .md-modal.md-cover.md-show .md-close:focus .icon-close, .md-modal.md-show.md-cover-dynamic .md-close:focus .icon-close {
  color: #000;
}

.md-modal.md-cover.md-show .md-content, .md-modal.md-show.md-cover-dynamic .md-content {
  transform: scale(1);
}

@media (min-width: 768px) {
  .md-modal.md-cover .md-close, .md-modal.md-cover-dynamic .md-close {
    top: 15px;
    right: 40px;
  }
}

.md-cover-dynamic .md-content.md-content {
  padding-top: 35px;
}

.md-content {
  *zoom: 1;
  position: relative;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  opacity: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  transform: translateY(20%);
}

.md-content:before, .md-content:after {
  display: table;
  content: " ";
}

.md-content:after {
  clear: both;
}

.md-content .md-close, .md-content .md-close-holder {
  position: absolute;
  top: -25px;
  right: -5px;
}

@media (min-width: 1024px) {
  .md-content {
    padding: 60px;
  }
}

.md-show {
  visibility: visible;
  pointer-events: auto;
}

.md-show .md-content {
  opacity: 1;
  transform: translateY(0);
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 766px) {
  .md-show {
    display: block;
  }
}

.md-close {
  text-align: center;
  padding: 0;
  font-size: 18px;
  background-color: transparent;
  border: none;
}

.md-close:after, .md-close:before {
  display: none;
}

.md-close .text {
  display: inline;
  padding: 0;
  background-color: e transparent;
  font-family: "Work Sans", Helvetica, Arial, Verdana, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  top: -4px;
  right: 0px;
}

@media (min-width: 768px) {
  .md-close .text {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.md-close .icon-close {
  color: #faecd8;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  margin-left: 3px;
}

.md-close:hover, .md-close:focus {
  background-color: transparent;
}

.md-close:hover .text, .md-close:focus .text {
  color: #fff;
}

.md-close:hover .icon-close, .md-close:focus .icon-close {
  color: #fff;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.75);
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

.noscroll {
  overflow: hidden;
}

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%;
}

body {
  font-family: "Work Sans", Helvetica, Arial, Verdana, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
  color: #000;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.page-wrap {
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .page-wrap {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 30px;
}

@media (min-width: 1024px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--no-margin {
  margin: 0;
  padding: 0;
}

.container--accent {
  padding: 0 40px;
}

@media (min-width: 768px) {
  .container--accent {
    padding: 0 160px;
  }
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.wf-loading {
  overflow: hidden;
}

.wf-loading body {
  visibility: hidden;
}

::selection {
  background-color: #f7e1c1;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 1px solid #000;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

.icon-hamburger {
  position: relative;
}

.icon-hamburger:before, .icon-hamburger:after {
  content: "";
  margin-left: 0;
}

.icon-hamburger:before {
  margin-top: -9px;
}

.icon-hamburger:after {
  margin-top: 8px;
}

.mt0.mt0 {
  margin-top: 0;
}

.mb0.mb0 {
  margin-bottom: 0;
}

.mb40.mb40 {
  margin-bottom: 40px;
}

.mt20.mt20 {
  margin-top: 20px;
}

.mt40.mt40 {
  margin-top: 40px;
}

.mt60.mt60 {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .mt60.mt60 {
    margin-top: 60px;
  }
}

@media (max-width: 766px) {
  .mobile--hide.mobile--hide {
    display: none;
  }
}

@media (max-width: 766px) {
  .mobile--mb0.mobile--mb0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .medium--mt40 {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .medium--mt60 {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  .hide--larger {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide--large {
    display: none !important;
  }
}

.show--larger {
  display: none;
}

@media (min-width: 1200px) {
  .show--larger {
    display: block !important;
  }
}

.show--large {
  display: none;
}

@media (min-width: 1024px) {
  .show--large {
    display: block !important;
  }
}

.ttl {
  text-transform: lowercase;
}

.tac {
  text-align: center;
}

.dib.dib {
  display: inline-block;
}

.gm-style img {
  max-width: inherit;
}

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}

.equal-h > * {
  display: flex;
}

.equal-h:before, .equal-h:after {
  display: none;
}

@media (min-width: 768px) {
  .medium--equal-h {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .medium--equal-h > * {
    display: flex;
  }
  .medium--equal-h:before, .medium--equal-h:after {
    display: none;
  }
}

@media (min-width: 480px) {
  .small--equal-h {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .small--equal-h > * {
    display: flex;
  }
  .small--equal-h:before, .small--equal-h:after {
    display: none;
  }
}

html.no-flexbox .equal-h,
html.no-flexbox .medium--equal-h {
  height: 100%;
}

html.no-flexbox .equal-h > div,
html.no-flexbox .medium--equal-h > div {
  float: left;
}

.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  padding: 5px 0;
  padding-bottom: 0;
}

.page-header:before, .page-header:after {
  display: table;
  content: " ";
}

.page-header:after {
  clear: both;
}

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
}

@media (min-width: 768px) {
  .page-header__logo {
    float: left;
    padding: 20px 0;
  }
}

.page-header__logo img {
  display: block;
  width: 150px;
}

@media (min-width: 768px) {
  .page-header__logo img {
    width: 150px;
  }
}

@media (min-width: 1024px) {
  .page-header__logo img {
    width: 150px;
  }
}

.page-header__logo:hover, .page-header__logo:focus {
  opacity: 0.8;
  backface-visibility: hidden;
}

.main-nav {
  text-transform: lowercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #000;
  margin-top: 20px;
  font-family: "brownregular", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

.main-nav a {
  text-decoration: none;
  color: #000;
  padding: 12px 15px;
  display: block;
}

.main-nav a:after {
  display: block;
  content: attr(data-title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.main-nav a:hover,
.main-nav a:focus,
.main-nav li.active > a {
  font-weight: bold;
}

@media (min-width: 768px) {
  .main-nav {
    margin: 40px 0 0 40px;
    border-bottom: none;
    float: right;
  }
  .main-nav li {
    float: left;
    margin: 0 0 0 35px;
  }
  .main-nav a {
    border-bottom: 0;
    padding: 0;
  }
}

@media (min-width: 980px) {
  .main-nav li {
    margin-left: 50px;
  }
}

@media (min-width: 1024px) {
  .main-nav li {
    margin-left: 75px;
  }
}

@media (min-width: 768px) {
  html[lang="en"] .main-nav__wrap__li--with-subnav ul,
  html[lang="nl"] .main-nav__wrap__li--with-subnav ul {
    transform: translateX(calc(-50% + 21px));
  }
}

.page-header__main-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: none;
}

.page-header__main-buttons a {
  font-size: 14px;
  font-size: 1.4rem;
  flex-grow: 1;
  border: 1px solid #d5d5d5;
  text-align: center;
  display: block;
  padding: 10px;
}

.page-header__main-buttons a:first-child {
  border-right: none;
}

@media (min-width: 768px) {
  .page-header__main-buttons {
    display: none;
  }
}

.main-nav__wrap__li--with-subnav {
  position: relative;
  z-index: 999;
}

.main-nav__wrap__li--with-subnav ul {
  margin-bottom: 0;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .main-nav__wrap__li--with-subnav ul {
    display: block;
    margin-left: 0;
    position: absolute;
    top: 0;
    margin-top: 15px;
    text-align: center;
    transform: translateX(-30%);
    max-height: 0;
    transition: 0.3s;
    overflow: hidden;
  }
  .main-nav__wrap__li--with-subnav ul:before {
    content: "";
    margin: 0 auto;
    margin-top: 15px;
    height: 18px;
    width: 1px;
    background-color: #000;
  }
}

.main-nav__wrap__li--with-subnav li {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0;
  float: none;
}

.main-nav__wrap__li--with-subnav li a {
  font-weight: 200;
  white-space: nowrap;
}

.main-nav__wrap__li--with-subnav.active ul, .main-nav__wrap__li--with-subnav:hover ul, .main-nav__wrap__li--with-subnav:focus ul, .main-nav__wrap__li--with-subnav:active ul {
  max-height: 200px;
}

.lang-wrap.lang-wrap {
  position: relative;
  margin-top: -4px;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap {
    height: 28px;
  }
}

.lang-wrap.lang-wrap.divider:before {
  display: none;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap.divider:before {
    display: inline-block;
  }
}

.lang-wrap.lang-wrap i {
  display: none;
  margin-left: 10px;
  font-size: 10px;
  font-size: 1rem;
  margin-top: 4px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap i {
    display: inline;
    margin-left: 50px;
  }
}

.lang-wrap.lang-wrap:hover .lang-selector {
  max-height: 100px;
}

.lang-wrap.lang-wrap:hover i {
  transform: rotate(180deg);
}

.lang-wrap.lang-wrap .lang-selector {
  margin-left: 0px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  margin-top: 20px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap .lang-selector {
    position: absolute;
    top: 0;
    left: 80px;
    margin-left: 0px;
    max-height: 28px;
    margin-bottom: 0;
    overflow: hidden;
    margin-top: 5px;
  }
}

.lang-wrap.lang-wrap .lang-selector li {
  margin-bottom: 0;
  float: none;
  padding: 0;
  float: left;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-size: 1.8rem;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap .lang-selector li {
    font-size: 16px;
    font-size: 1.6rem;
    display: inline-block;
    float: none;
    padding: 5px 10px;
  }
}

.lang-wrap.lang-wrap .lang-selector li:first-child:after {
  content: "";
  width: 40px;
  height: 1px;
  background-color: #777;
  margin: 0 7px;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap .lang-selector li:first-child:after {
    content: none;
  }
}

.lang-wrap.lang-wrap .lang-selector li a {
  margin: 0;
  display: inline-block;
  border-bottom: none;
}

.lang-wrap.lang-wrap .lang-selector li:last-child {
  border-right: 0;
}

@media (min-width: 768px) {
  .lang-wrap.lang-wrap .lang-selector li {
    padding: 0;
    display: block;
    margin-left: 0;
    border-right: 0;
    margin-bottom: 10px;
  }
}

.main-nav__trigger {
  display: block;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* position: absolute; top: 15px; right: 20px;
background-color: transparent;position: relative; padding: 12px 20px 12px 35px;
  height: 65px;
  width: 65px;*/
  border: none;
  position: fixed;
  background-color: #fff;
  top: 15px;
  right: 15px;
  z-index: 99999;
  height: auto;
  width: auto;
  padding: 22px 20px 22px 35px;
}

.main-nav__trigger .icon-hamburger {
  top: 25px;
}

.main-nav__trigger:after {
  content: none;
}

@media (min-width: 768px) {
  .main-nav__trigger:after {
    content: "";
  }
}

.main-nav__trigger .icon-hamburger {
  position: absolute;
  left: 10px;
}

.main-nav__trigger:hover, .main-nav__trigger:focus {
  background-color: transparent;
  box-shadow: none;
}

.main-nav__trigger:active {
  transform: none;
}

@media (min-width: 768px) {
  .main-nav__trigger {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;
}

@media (min-width: 768px) {
  .main-nav__wrap {
    margin: 0 0 0 -20px;
    display: block;
  }
}

@media (min-width: 768px) {
  .main-nav__wrap > li:first-child {
    display: none;
  }
}

.md-modal .main-nav__wrap {
  display: block;
  margin-top: 20px;
}

.md-modal .main-nav__wrap li {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 26px;
  line-height: 2.6rem;
}

.md-modal .main-nav__wrap li a {
  display: inline-block;
}

.md-modal .main-nav__wrap li.main-nav__wrap__li--with-subnav li {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 22px;
  line-height: 2.2rem;
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;
}

.skip-link:focus {
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  padding: 0.5em 1em;
  font-size: 20px;
  font-size: 2rem;
}

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  padding: 0;
  /*@media (min-width: 1400px) {
    padding-bottom: 120px;
  }*/
}

@media (min-width: 768px) {
  .main {
    padding: 50px 0;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .main {
    padding-bottom: 100px;
  }
}

@media (min-width: 1300px) {
  .main {
    padding-bottom: 80px;
  }
}

.home .main,
.about .main {
  padding-top: 0;
}

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  margin-bottom: 20px;
  /*  @media (min-width: 1300px) {
    height: 30px;
  //  margin-top: -50px;
  }*/
}

.page-footer a {
  line-height: 20px;
  line-height: 2rem;
}

.page-footer > .container {
  *zoom: 1;
  /*    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }*/
}

.page-footer > .container:before, .page-footer > .container:after {
  display: table;
  content: " ";
}

.page-footer > .container:after {
  clear: both;
}

@media (min-width: 1400px) {
  .page-footer > .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.page-footer ul {
  list-style: none;
  margin-bottom: 0;
  *zoom: 1;
}

.page-footer ul:before, .page-footer ul:after {
  display: table;
  content: " ";
}

.page-footer ul:after {
  clear: both;
}

.page-footer ul li {
  float: left;
  margin-left: 10px;
}

.page-footer ul li:first-child {
  margin-left: 0;
}

.page-footer ul li.divider {
  margin-left: 0;
}

.page-footer ul li.divider:first-child {
  margin-left: 5px;
}

.page-footer ul li.divider:before {
  margin-left: 0px;
  margin-right: 10px;
}

.page-footer ul li.small-margin {
  margin-left: 5px;
}

.page-footer ul li.small-margin.divider:before {
  margin-right: 5px;
}

.page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full, .page-footer .grid--g40, .page-footer .grid--g50, .page-footer .grid--g80, .page-footer .grid--g100, .page-footer .grid--g120, .page-footer .grid--g160, .page-footer .blog-slideshow-wrap__item {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .page-footer {
    /* height: 75px;*/
    margin: 0;
    z-index: 5;
    height: 130px;
    margin-top: -110px;
  }
}

@media (min-width: 1024px) {
  .page-footer {
    height: 100px;
    margin-top: -80px;
  }
}

@media (min-width: 1200px) {
  .page-footer {
    height: 80px;
    margin-top: -50px;
  }
}

@media (min-width: 1400px) {
  .page-footer {
    height: 60px;
    margin-top: -40px;
  }
}

@media (min-width: 768px) {
  .page-footer__side:nth-child(2) {
    padding-left: 40px;
  }
}

@media (min-width: 1024px) {
  .page-footer__side:nth-child(2) {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .page-footer__side {
    width: 50%;
    float: left;
  }
}

@media (min-width: 1024px) {
  .page-footer__side {
    width: 100%;
    display: inline-block;
    float: none;
  }
}

@media (min-width: 1300px) {
  .page-footer__side {
    width: auto;
  }
}

.page-footer__side ul {
  margin-left: 0;
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .page-footer__side ul {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .page-footer__side ul {
    width: auto;
    display: flex;
    align-items: center;
  }
}

.page-footer__side ul li,
.page-footer__side ul li.small-margin {
  display: inline-block;
  margin: 5px 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .page-footer__side ul li,
  .page-footer__side ul li.small-margin {
    width: auto;
    display: flex;
    margin: 0 5px;
  }
}

.page-footer__side ul .divider {
  display: block;
}

.page-footer__side ul .divider:before {
  display: none;
}

@media (min-width: 1024px) {
  .page-footer__side ul .divider {
    display: flex;
  }
  .page-footer__side ul .divider:before {
    display: inline-block;
    width: 30px;
  }
}

.page-footer__side--social {
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  flex-wrap: wrap;
  /*  @media (min-width: 1400px) {

  }*/
}

@media (min-width: 768px) {
  .page-footer__side--social {
    padding-top: 0;
    margin-top: 0;
    display: block;
    border: none;
  }
}

@media (min-width: 1024px) {
  .page-footer__side--social {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

@media (min-width: 1400px) {
  .page-footer__side--social {
    margin-top: 0;
    margin-left: auto;
  }
}

.page-footer__side--social .divider:before {
  margin-right: 20px;
  margin-left: 20px;
}

@media (min-width: 1024px) {
  .page-footer__side--social .divider:before {
    margin-left: 0;
  }
}

.page-footer__side--social > span {
  /* @media (min-width: 1300px) {

    }*/
}

@media (min-width: 768px) {
  .page-footer__side--social > span {
    display: block;
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .page-footer__side--social > span {
    float: left;
    margin-right: 0;
  }
}

.page-footer__side--social .divider:before {
  display: flex;
}

@media (min-width: 768px) {
  .page-footer__side--social .divider:before {
    display: none;
  }
}

@media (min-width: 1024px) {
  .page-footer__side--social .divider:before {
    display: flex;
  }
}

.page-footer__side--social ul.social-list {
  width: auto;
  margin-left: 0;
  display: flex;
  /*  @media (min-width: 1200px) {
      margin-left: 20px;

    }*/
}

@media (min-width: 768px) {
  .page-footer__side--social ul.social-list {
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .page-footer__side--social ul.social-list {
    margin-left: 20px;
    display: flex;
  }
}

.page-footer__side--social ul.social-list li {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}

@media (max-width: 766px) {
  .page-footer__side--social ul.social-list li {
    font-size: 18px;
    font-size: 1.8rem;
    margin-right: 20px;
  }
}

.esign-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  height: 20px;
  margin-top: 3px;
  width: 15px;
}

@media (min-width: 480px) {
  .esign-wrapper {
    width: 78px;
  }
}

@media (min-width: 768px) {
  .esign-wrapper {
    width: 115px;
  }
}

a.esign {
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  background-size: 77px 20px;
}

a.esign:hover, a.esign:focus {
  opacity: 1;
}

@media (min-width: 480px) {
  a.esign {
    right: 45px;
  }
}

@media (min-width: 768px) {
  a.esign {
    background-size: 65px 15px;
    width: 16px;
    position: absolute;
    bottom: 0;
  }
  a.esign:hover, a.esign:focus {
    opacity: 1;
    width: 80px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  a.esign:hover, a.esign:focus {
    width: 70px;
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.small-indent {
  margin-left: -20px;
}

@media (min-width: 768px) {
  .small-indent {
    margin-left: -50px;
  }
}

.medium--small-indent {
  margin-left: 0px;
}

@media (min-width: 768px) {
  .medium--small-indent {
    margin-left: -50px;
  }
}

/*.large-indent {
  margin-left:-50px;
}*/
.grid .grid__item.no-padding-indent > .small-indent, .grid--rev .grid__item.no-padding-indent > .small-indent, .grid--full .grid__item.no-padding-indent > .small-indent, .grid--g40 .grid__item.no-padding-indent > .small-indent, .grid--g50 .grid__item.no-padding-indent > .small-indent, .grid--g80 .grid__item.no-padding-indent > .small-indent, .grid--g100 .grid__item.no-padding-indent > .small-indent, .grid--g120 .grid__item.no-padding-indent > .small-indent, .grid--g160 .grid__item.no-padding-indent > .small-indent, .blog-slideshow-wrap__item .grid__item.no-padding-indent > .small-indent {
  margin-left: -40px;
}

@media (min-width: 768px) {
  .grid .grid__item.no-padding-indent > .small-indent, .grid--rev .grid__item.no-padding-indent > .small-indent, .grid--full .grid__item.no-padding-indent > .small-indent, .grid--g40 .grid__item.no-padding-indent > .small-indent, .grid--g50 .grid__item.no-padding-indent > .small-indent, .grid--g80 .grid__item.no-padding-indent > .small-indent, .grid--g100 .grid__item.no-padding-indent > .small-indent, .grid--g120 .grid__item.no-padding-indent > .small-indent, .grid--g160 .grid__item.no-padding-indent > .small-indent, .blog-slideshow-wrap__item .grid__item.no-padding-indent > .small-indent {
    margin-left: -70px;
  }
}

.visual-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
}

.visual-bg img {
  visibility: hidden;
}

.visual-bg.bgsize--contain {
  background-size: contain;
  background-repeat: no-repeat;
}

.overlay-block-link {
  display: inline-block;
  position: static;
  z-index: 1;
  float: none;
  clear: both;
}

.overlay-block-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.large {
  font-size: 20px;
  font-size: 2rem;
}

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0;
}

.center-block:before, .center-block:after {
  display: table;
  content: " ";
}

.center-block:after {
  clear: both;
}

.center-block .button {
  float: none;
  display: inline-block;
}

section {
  position: relative;
}

article {
  *zoom: 1;
  margin: 0 0 20px;
}

article:before, article:after {
  display: table;
  content: " ";
}

article:after {
  clear: both;
}

article a img {
  display: block;
  opacity: 1;
  backface-visibility: hidden;
}

article a:hover img, article a:focus img {
  opacity: 0.8;
  backface-visibility: hidden;
}

.article-detail__image {
  margin-bottom: 1.5em;
}

.note {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;
}

.note:before, .note:after {
  display: table;
  content: " ";
}

.note:after {
  clear: both;
}

.note ul,
.note p {
  margin-bottom: 1em;
}

.note--success {
  color: #39963a;
  background-color: #eff9ef;
}

.note--error {
  color: #f1062c;
  background-color: #fec5cf;
}

.box {
  padding: 10px;
  margin-bottom: 40px;
}

.box ul {
  list-style: none;
}

.box.success {
  background-color: #eff9ef;
}

.box.error {
  background-color: #fec5cf;
}

.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;
}

.cycle-pager span {
  display: inline-block;
  margin: 0 3px;
  font-size: 36px;
  height: 20px;
  width: 20px;
  line-height: 14px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  overflow: hidden;
  text-indent: -500px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

.cycle-pager span:hover, .cycle-pager span:focus {
  transition: none;
}

.cycle-pager span:hover,
.cycle-pager span:focus,
.cycle-pager .cycle-pager-active {
  text-indent: 0;
}

.divider {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.divider:before {
  content: "";
  width: 50px;
  height: 1px;
  background-color: #000;
  display: inline-block;
}

.divider--small:before {
  width: 15px;
}

.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;
}

@media (min-width: 768px) {
  .map {
    height: 550px;
  }
}

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social:before, .social:after {
  display: table;
  content: " ";
}

.social:after {
  clear: both;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: #faecd8;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
}

.social__link--facebook:hover, .social__link--facebook:focus {
  background-color: #314a7e;
}

.social__link--twitter {
  background-color: #2daae1;
}

.social__link--twitter:hover, .social__link--twitter:focus {
  background-color: #1d94c9;
}

.social__link--pinterest {
  background-color: #cb2027;
}

.social__link--pinterest:hover, .social__link--pinterest:focus {
  background-color: #a81a20;
}

.social__link--linkedin {
  background-color: #0173b2;
}

.social__link--linkedin:hover, .social__link--linkedin:focus {
  background-color: #015989;
}

/* =============================================================================
   PAGES
   ========================================================================== */
/* =================================================
   HOME
   ================================================= */
.homepage-section {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .homepage-section {
    margin-bottom: 140px;
  }
}

.homepage-section--accent {
  background-color: #faecd8;
  margin-bottom: 0;
  padding: 40px 0;
  background-image: url("../images/brushes_2.svg");
  background-size: cover;
}

@media (min-width: 768px) {
  .homepage-section--accent {
    padding: 100px 0;
  }
}

.homepage-section--blog {
  padding-bottom: 20px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .accent-block-grid--center {
    display: flex;
    justify-content: center;
  }
}

.accent-block-wrap {
  position: relative;
}

@media (min-width: 768px) {
  .accent-block-wrap {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .accent-block-wrap {
    margin-bottom: 200px;
  }
}

.accent-block-wrap:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -170px;
  margin-top: -230px;
  width: 66.66%;
  height: calc(100% + 250px);
  padding: 40px;
  background-color: #faecd8;
  z-index: -1;
}

@media (min-width: 768px) {
  .accent-block-wrap:before {
    opacity: 1;
    height: calc(100% + 175px);
  }
}

@media (min-width: 1200px) {
  .accent-block-wrap:before {
    height: calc(100% + 230px);
  }
}

@media (min-width: 768px) {
  .accent-block-wrap .medium--equal-h > .grid__item {
    display: block;
  }
}

.accent-block__visual.accent-block__visual--home {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .accent-block__visual.accent-block__visual--home {
    margin-bottom: 0;
  }
}

.accent-block__visual.accent-block__visual--home img {
  float: none;
}

@media (min-width: 768px) {
  .accent-block-wrap--about {
    padding-top: 70px;
  }
}

@media (min-width: 1200px) {
  .accent-block-wrap--about {
    padding-top: 80px;
  }
}

.accent-block-wrap--about:before {
  left: 50%;
  right: auto;
  width: 50%;
}

.accent-block__text-wrap.accent-block__text-wrap--about {
  padding-left: 0;
}

.about-body strong {
  font-weight: 400;
}

.about-body a {
  text-decoration: underline;
}

.about-body p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .about-body p {
    margin-bottom: 50px;
  }
}

.accent-block__visual {
  height: auto;
  width: 100%;
}

.accent-block__visual img {
  width: 100%;
}

@media (min-width: 768px) {
  .accent-block__visual {
    height: 100%;
  }
}

.accent-block__visual--about {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .accent-block__visual--about {
    height: 500px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .accent-block__visual--about {
    height: 750px;
  }
}

@media (max-width: 766px) {
  .accent-block__header--about {
    padding-left: 30px;
  }
}

.accent-block__text-wrap {
  padding-left: 0;
  position: relative;
  z-index: 9;
}

@media (min-width: 768px) {
  .accent-block__text-wrap .medium--small-indent {
    margin-left: -90px;
  }
}

@media (min-width: 768px) {
  .accent-block__text-wrap {
    padding-left: 20px;
    max-width: 500px;
    padding-right: 40px;
  }
}

.accent-block__text-wrap--left {
  padding-right: 0px;
  /*  padding-left: 40px;

  @media (min-width: 768px) {
    padding-right: 0px;
    padding-left: 60px;
  }*/
}

@media (min-width: 768px) {
  .accent-block__visual__img {
    float: right;
  }
}

.accent-block-wrap--home {
  padding: 0 30px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .accent-block-wrap--home {
    padding: 0;
    margin-bottom: 80px;
  }
}

@media (min-width: 1300px) {
  .accent-block-wrap--home {
    margin-bottom: 120px;
  }
}

@media (min-width: 1400px) {
  .accent-block-wrap--home {
    margin-bottom: 150px;
  }
}

@media (min-width: 1680px) {
  .accent-block-wrap--home > .grid, .accent-block-wrap--home > .grid--rev, .accent-block-wrap--home > .grid--full, .accent-block-wrap--home > .grid--g40, .accent-block-wrap--home > .grid--g50, .accent-block-wrap--home > .grid--g80, .accent-block-wrap--home > .grid--g100, .accent-block-wrap--home > .grid--g120, .accent-block-wrap--home > .grid--g160, .accent-block-wrap--home > .blog-slideshow-wrap__item {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .accent-block-wrap--home:before {
    height: calc(100% + 200px);
  }
}

@media (min-width: 1300px) {
  .accent-block-wrap--home:before {
    height: calc(100% + 250px);
  }
}

@media (min-width: 1400px) {
  .accent-block-wrap--home:before {
    height: calc(100% + 300px);
  }
}

.accent-block-wrap--home .button {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .accent-block-wrap--home .button {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .accent-block-wrap--home .button {
    margin-bottom: 80px;
  }
}

.accent-block__header--home {
  margin-top: 0px;
}

@media (max-width: 766px) {
  .accent-block__header--home {
    margin-bottom: 15px;
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .accent-block__header--home {
    max-width: 400px;
    margin-top: 75px;
  }
}

.accent-block__visual-wrap {
  position: relative;
}

.accent-block__scroll-wrap {
  display: none;
  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  margin-bottom: -50px;
}

@media (min-width: 768px) {
  .accent-block__scroll-wrap {
    display: block;
    margin-left: -20px;
  }
}

@media (min-width: 1200px) {
  .accent-block__scroll-wrap {
    margin-left: 0;
  }
}

.accent-block__scroll-wrap .rotate-container {
  position: relative;
}

.accent-block__scroll-wrap .rotate-container__content {
  transform: translate(0, 0);
  position: absolute;
}

.scroll-line {
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.scroll-line:before {
  content: "";
  display: inline-block;
  background-image: url("../images/little-arrow.svg");
  width: 10px;
  height: 10px;
  background-size: contain;
  transform: rotate(90deg);
  margin-right: 10px;
}

.scroll-line:after {
  content: "";
  display: inline-block;
  width: 37px;
  height: 1px;
  background-color: #000;
  margin-left: 10px;
}

.product-collection-overview > * {
  display: block;
}

@media (min-width: 768px) {
  .wrap-visual-cta {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .products-collection__body--home {
    max-width: 500px;
  }
}

.products-collection-visual-wrap {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  margin-bottom: 20px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .products-collection-visual-wrap {
    margin-bottom: 0;
  }
}

.products-collection-visual-wrap a {
  display: block;
}

@media (min-width: 768px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--left, .products-collection-visual-wrap a:focus .products-collection-visual__title--left, .products-collection-visual-wrap a:active .products-collection-visual__title--left {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right, .products-collection-visual-wrap a:focus .products-collection-visual__title--right, .products-collection-visual-wrap a:active .products-collection-visual__title--right {
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right, .products-collection-visual-wrap a:focus .products-collection-visual__title--right, .products-collection-visual-wrap a:active .products-collection-visual__title--right {
    padding-right: 60px;
  }
}

@media (min-width: 480px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    max-width: calc(200px + 25px);
  }
}

@media (min-width: 768px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    max-width: calc(260px + 25px);
  }
}

@media (min-width: 1200px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    padding-right: 60px;
    max-width: calc(300px + 25px);
  }
}

@media (min-width: 1400px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    max-width: calc(380px + 25px);
  }
}

@media (min-width: 1680px) {
  .products-collection-visual-wrap a:hover .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:focus .products-collection-visual__title--right-bottom, .products-collection-visual-wrap a:active .products-collection-visual__title--right-bottom {
    max-width: none;
  }
}

@media (min-width: 480px) {
  .products-collection-visual-wrap a:hover .products-collection-visual-wrap__visual, .products-collection-visual-wrap a:focus .products-collection-visual-wrap__visual, .products-collection-visual-wrap a:active .products-collection-visual-wrap__visual {
    transform: scale(1.1);
  }
}

.blog-slideshow-wrap {
  position: relative;
  text-decoration: none;
  margin-bottom: 20px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .blog-slideshow-wrap {
    margin-bottom: 0;
    height: 450px;
  }
}

@media (min-width: 1024px) {
  .blog-slideshow-wrap {
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .blog-slideshow-wrap {
    height: 500px;
  }
}

.blog-slideshow-wrap p {
  max-height: 120px;
}

.blog-slideshow-wrap a {
  display: block;
}

@media (min-width: 768px) {
  .blog-slideshow-wrap a {
    height: 100%;
  }
}

.blog-slideshow-wrap__item {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  margin-bottom: 20px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .blog-slideshow-wrap__item {
    margin-bottom: 0;
    height: 450px;
  }
}

.blog-slideshow-wrap__item a {
  display: block;
  height: 100%;
  height: calc(100% - 20px);
}

.products-collection-visual-wrap--preview {
  height: 125px;
}

.products-collection-visual-wrap__visual {
  height: 0;
  padding-bottom: 56%;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .products-collection-visual-wrap__visual {
    width: 100%;
    padding-bottom: 63%;
  }
}

.news-article .products-collection-visual__title--blog {
  position: static;
}

.products-collection-visual-wrap__visual--preview {
  width: 50%;
}

.products-collection-visual__title {
  margin-right: 0;
  right: 0;
  background-color: #fff;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  margin-top: 10px;
  margin-bottom: 0px;
}

@media (min-width: 480px) {
  .products-collection-visual__title {
    position: absolute;
    bottom: 0;
    padding: 15px;
    max-width: 200px;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .products-collection-visual__title {
    padding: 20px;
    margin-top: 55px;
    top: 0;
    bottom: auto;
    max-width: 260px;
    /*&:hover {
      padding-right: 60px;
    }*/
  }
}

@media (min-width: 1200px) {
  .products-collection-visual__title {
    padding: 35px;
  }
}

.products-collection-visual__title--left {
  left: 0;
  right: auto;
}

@media (min-width: 768px) {
  .products-collection-visual__title--right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1024px) {
  .products-collection-visual__title--right-bottom {
    margin-bottom: 55px;
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;
  }
}

@media (min-width: 1200px) {
  .products-collection-visual__title--right-bottom {
    max-width: 300px;
  }
}

@media (min-width: 1400px) {
  .products-collection-visual__title--right-bottom {
    max-width: 380px;
  }
}

@media (min-width: 1680px) {
  .products-collection-visual__title--right-bottom {
    max-width: none;
  }
}

/*.products-collection-visual__title--left {

  padding-left: 0;
  @media (min-width: 768px) {
    padding-left: 20px;
  }


}*/
.products-collection-visual__title--preview {
  margin-top: 20px;
  padding: 3px;
  max-width: 250px;
}

.products-collection-visual__title--preview:hover {
  margin-right: 10px;
  padding-right: 3px;
}

.small--visualbg-right .visual-bg {
  background-position-x: left;
}

/* =================================================
   Products
   ================================================= */
.product-grid {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .product-grid {
    margin-bottom: 60px;
  }
}

.product-grid > .grid__item {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .product-grid > .grid__item {
    margin-bottom: 40px;
  }
}

.product-grid > .grid__item:before {
  content: "";
  float: left;
}

@media (min-width: 480px) {
  .product-grid > .grid__item:before {
    padding-top: 100%;
  }
}

.product-grid-item-wrap {
  position: relative;
  border: 1px solid #d5d5d5;
  text-align: center;
  width: 100%;
}

.product-grid-item-wrap a {
  width: 100%;
  display: block;
  height: 0;
  padding-bottom: 100%;
}

.product-grid-item-wrap a:hover, .product-grid-item-wrap a:focus, .product-grid-item-wrap a:active {
  color: #000;
}

.product-grid-item-wrap:after {
  z-index: -1;
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background-color: #faecd8;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  pointer-events: none;
}

.product-grid-item-wrap:before {
  content: "\e912";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  bottom: -20px;
  transform: rotate(180deg);
  margin-bottom: 25px;
  font-size: 8px;
  font-size: 0.8rem;
  z-index: 11;
  right: 20px;
  opacity: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  pointer-events: none;
}

@media (min-width: 768px) {
  .product-grid-item-wrap:hover:after {
    height: 50%;
  }
  .product-grid-item-wrap:hover:before {
    transform: rotate(180deg) translateY(20px);
    opacity: 1;
  }
}

.product-grid-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .product-grid-item {
    display: flex;
    flex-flow: column;
  }
}

.product-grid-item .product-grid-item__img-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 100%;
  overflow: hidden;
  text-align: center;
}

@media (min-width: 768px) {
  .product-grid-item .product-grid-item__img-wrap {
    transform: translate(-50%, -50%);
    flex-grow: 1;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
  }
}

@media (min-width: 1024px) {
  .product-grid-item .product-grid-item__img-wrap {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .product-grid-item .product-grid-item__img-wrap {
    margin-top: -20px;
  }
}

.product-grid-item img {
  padding: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-block;
  max-height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  font-family: "object-fit: contain";
}

@media (min-width: 480px) {
  .product-grid-item img {
    max-height: 140px;
  }
}

@media (min-width: 768px) {
  .product-grid-item img {
    max-width: 300px;
    max-height: 200px;
  }
}

@media (min-width: 1024px) {
  .product-grid-item img {
    padding: 20px;
    max-height: 150px;
  }
}

@media (min-width: 1200px) {
  .product-grid-item img {
    padding: 0px;
    padding-top: 15px;
    max-height: 200px;
  }
}

@media (min-width: 1680px) {
  .product-grid-item img {
    max-height: 250px;
  }
}

@media (min-width: 768px) {
  .product-grid-item--cheese .product-grid-item__img-wrap img {
    max-height: 350px;
  }
}

@media (min-width: 1024px) {
  .product-grid-item--cheese .product-grid-item__img-wrap img {
    max-height: 200px;
  }
}

@media (min-width: 1200px) {
  .product-grid-item--cheese .product-grid-item__img-wrap img {
    padding-top: 30px;
    max-height: 250px;
  }
}

@media (min-width: 1400px) {
  .product-grid-item--cheese .product-grid-item__img-wrap img {
    max-height: none;
  }
}

.product-grid-item__title {
  padding: 20px;
  align-self: flex-end;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-align: cener;
  margin-left: 0;
  font-family: "Work Sans", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-size: 20px;
  font-size: 2rem;
  margin-top: auto;
}

@media (min-width: 480px) {
  .product-grid-item__title {
    font-size: 20px;
    font-size: 2rem;
  }
}

.product-grid-item__title:after {
  text-align: right;
  content: "\e912";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  transform: rotate(180deg);
  margin-top: 5px;
  font-size: 7px;
  font-size: 0.7rem;
  margin-left: 20px;
}

@media (min-width: 480px) {
  .product-grid-item__title:after {
    font-size: 6px;
    font-size: 0.6rem;
  }
}

@media (min-width: 768px) {
  .product-grid-item__title:after {
    content: none;
  }
}

@media (min-width: 768px) {
  .product-grid-item__title {
    display: block;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .product-grid-item__title {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (min-width: 1300px) {
  .product-grid-item__title {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.catalogus-cta {
  padding: 30px;
  background-color: #faecd8;
  margin-bottom: 20px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .catalogus-cta {
    margin-bottom: 0;
    padding: 40px;
  }
}

.catalogus-cta > span {
  display: block;
}

.catalogus-cta .floating-label--container {
  display: block;
}

@media (min-width: 768px) {
  .catalogus-cta .floating-label--container {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .catalogus-cta .floating-label--container {
    width: auto;
    margin-right: 20px;
    flex-grow: 1;
  }
}

@media (min-width: 1400px) {
  .catalogus-cta .floating-label--container {
    margin-left: 20px;
  }
}

.catalogus-cta ::placeholder {
  font-style: normal;
  text-transform: lowercase;
}

.catalogus-cta input[type="email"] {
  background: transparent;
  padding-left: 15px;
  border: 1px solid #000;
}

@media (min-width: 768px) {
  .catalogus-cta__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.catalogus-cta__form input[type="email"] {
  height: 50px;
}

@media (min-width: 768px) {
  .catalogus-cta__form input[type="email"] {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .catalogus-cta__form input[type="email"] {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .catalogus-cta__form input[type="email"] {
    width: 100%;
  }
}

@media (min-width: 1680px) {
  .catalogus-cta__form input[type="email"] {
    width: 100%;
  }
}

.catalogus-cta__form span,
.catalogus-cta__form input[type="email"] {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .catalogus-cta__form span,
  .catalogus-cta__form input[type="email"] {
    margin-bottom: 0;
  }
}

.catalogus-cta__form span {
  display: block;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .catalogus-cta__form span {
    display: inline;
    margin-bottom: 10px;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .catalogus-cta__form span {
    width: auto;
    margin-bottom: 0;
  }
}

.catalogus-cta__form button {
  margin: 0;
}

@media (min-width: 768px) {
  .catalogus-cta__form button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .catalogus-cta__form button {
    margin-left: auto;
  }
}

@media (min-width: 1680px) {
  .catalogus-cta__form button {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.product-group-banners {
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .product-group-banners {
    margin-bottom: 60px;
  }
}

.product-group-banners__banner {
  position: relative;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .product-group-banners__banner {
    margin-bottom: 20px;
  }
}

.product-group-banners__banner a {
  overflow: hidden;
  width: 100%;
  display: block;
}

@media (min-width: 1024px) {
  .product-group-banners__banner a {
    height: 200px;
  }
}

.product-group-banners__banner a:hover .product-group-banners__visual-bg, .product-group-banners__banner a:focus .product-group-banners__visual-bg, .product-group-banners__banner a:active .product-group-banners__visual-bg {
  transform: scale(1.1);
}

.product-group-banners__visual-bg {
  height: auto;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 1024px) {
  .product-group-banners__visual-bg {
    height: 100%;
  }
}

.product-group-banners__visual-bg img {
  display: block;
  width: 100%;
}

.product-group-banners__banner__title {
  padding-top: 15px;
  padding-right: 15px;
  background-color: #fff;
  margin-bottom: 0;
  max-width: 190px;
  transform: translateY(-35px);
}

.product-group-banners__banner__title .h3 {
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .product-group-banners__banner__title {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .product-group-banners__banner__title {
    padding-left: 20px;
    max-width: 90%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 20px;
    padding-right: 20px;
    transform: none;
  }
}

/* =================================================
   Product Detail
   ================================================= */
.product-detail .h3 {
  margin-bottom: 5px;
  margin-top: 20px;
}

.product-box {
  position: relative;
  padding: 0;
}

@media (min-width: 768px) {
  .product-box {
    height: 0;
    padding-bottom: 100%;
  }
}

.product-box .visual-bg {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.product-box .visual-bg img {
  visibility: hidden;
}

@media (min-width: 768px) {
  .product-box .visual-bg {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.product-box:after {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 100%;
  background-color: #faecd8;
  height: 280px;
  transform: translate(-50%, -45%);
  height: 65%;
}

.product-box-types {
  list-style: none;
  margin-bottom: 40px;
  font-size: 13px;
  font-size: 1.3rem;
}

@media (min-width: 480px) {
  .product-box-types {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.product-box-types a {
  text-align: center;
  width: 100%;
  padding: 15px 5px;
  white-space: normal;
}

.product-box-types a.active {
  background-color: #000;
  color: #fff;
}

@media (min-width: 768px) {
  .product-box-types a {
    padding: 15px 25px;
  }
}

@media (min-width: 1200px) {
  .product-box-types a {
    padding: 15px 40px;
  }
}

@media (min-width: 1024px) {
  .product-box-types .large--one-quarter {
    width: 33.33%;
  }
}

.product-box-inside > .grid__item {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .medium--flex {
    display: flex;
  }
  .medium--flex-end {
    align-self: flex-end;
  }
}

/* =================================================
   About
   ================================================= */
.product-with-prize {
  position: relative;
}

@media (min-width: 768px) {
  .product-with-prize {
    margin-bottom: 0;
  }
}

.product-with-prize > img {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 35px;
  margin-bottom: -20px;
  width: 75px;
}

@media (min-width: 768px) {
  .product-with-prize > img {
    margin-bottom: 15px;
    top: 0;
    bottom: auto;
  }
}

@media (min-width: 1200px) {
  .product-with-prize > img {
    width: 125px;
    margin-top: -30px;
    margin-bottom: 25px;
    margin-right: -20px;
  }
}

/* =================================================
   BLOG
   ================================================= */
.blog-grid {
  list-style: none;
  margin-left: 0;
}

.news-article .blog-grid-item__title {
  background-color: #fff;
  color: #000;
}

@media (min-width: 768px) {
  .news-article .blog-grid-item__title {
    margin-left: -200px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .news-article .blog-grid-item__title {
    padding-right: 150px;
    margin-left: -300px;
  }
}

.blog-grid-item {
  position: relative;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .blog-grid-item {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .blog-grid-item {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) {
  .blog-grid-item:nth-child(odd) .blog-grid-item__title {
    margin-left: -200px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .blog-grid-item:nth-child(odd) .blog-grid-item__title {
    padding-right: 150px;
    margin-left: -300px;
  }
}

@media (min-width: 768px) {
  .blog-grid-item:nth-child(even) .article-teaser .grid, .blog-grid-item:nth-child(even) .article-teaser .grid--rev, .blog-grid-item:nth-child(even) .article-teaser .grid--full, .blog-grid-item:nth-child(even) .article-teaser .grid--g40, .blog-grid-item:nth-child(even) .article-teaser .grid--g50, .blog-grid-item:nth-child(even) .article-teaser .grid--g80, .blog-grid-item:nth-child(even) .article-teaser .grid--g100, .blog-grid-item:nth-child(even) .article-teaser .grid--g120, .blog-grid-item:nth-child(even) .article-teaser .grid--g160, .blog-grid-item:nth-child(even) .article-teaser .blog-slideshow-wrap__item {
    justify-content: flex-end;
  }
}

.blog-grid-item:nth-child(even) .blog-grid-item__visual-item {
  order: 2;
}

.blog-grid-item:nth-child(even) .blog-grid-item__info-item {
  text-align: left;
  order: 1;
}

@media (min-width: 768px) {
  .blog-grid-item:nth-child(even) .blog-grid-item__info-item {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .blog-grid-item:nth-child(even) .blog-grid-item__title {
    margin-right: -300px;
    padding-right: 50px;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .blog-grid-item:nth-child(even) .blog-grid-item__title {
    margin-right: -200px;
    padding-right: 100px;
    margin-left: 0px;
  }
}

.blog-grid-item__link {
  display: block;
}

.blog-grid-item__link:hover p, .blog-grid-item__link:focus p, .blog-grid-item__link:active p {
  color: #000;
}

.blog-grid-item__link:hover .blog-grid-item__visual-wrap .blog-grid-item__visual, .blog-grid-item__link:focus .blog-grid-item__visual-wrap .blog-grid-item__visual, .blog-grid-item__link:active .blog-grid-item__visual-wrap .blog-grid-item__visual {
  transform: scale(1.1);
}

.blog-grid-item__visual-wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.blog-grid-item__visual {
  width: 100%;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .blog-grid-item__visual {
    height: auto;
  }
}

.blog-grid-item__visual img {
  display: none;
}

@media (min-width: 768px) {
  .blog-grid-item__info-item > div {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.blog-grid-item__body {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .blog-grid-item__body {
    width: 325px;
  }
}

@media (min-width: 1400px) {
  .blog-grid-item__body {
    max-width: 325px;
  }
}

@media (min-width: 768px) {
  .blog-grid-item__title,
  .blog-grid-item__body {
    width: auto;
  }
}

.blog-grid-item__title {
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  background: #fff;
  color: #000;
  padding: 20px;
  padding-left: 0;
  z-index: 5;
  position: relative;
  left: 0;
  max-width: 650px;
  margin-top: -40px;
  width: 90%;
}

@media (min-width: 768px) {
  .blog-grid-item__title {
    width: auto;
    padding: 35px;
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .blog-grid-item__title {
    margin-top: 50px;
  }
}

@media (min-width: 768px) {
  .blog-grid-item__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
  }
  .blog-grid-item__content .blog-grid-item__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .blog-grid-item__content .blog-grid-item__body,
  .blog-grid-item__content .blog-grid-item__body__top {
    flex: 1 1 auto;
    flex-shrink: 1;
    max-width: 100%;
  }
}

/* =================================================
   Blog detail
   ================================================= */
@media (min-width: 768px) {
  .blog-detail__image-wrap {
    position: absolute;
    left: 0;
  }
}

.blog-detail__image {
  width: 100%;
}

@media (min-width: 768px) {
  .blog-detail__image {
    height: 100%;
  }
}

.blog-detail__image img {
  width: 100%;
}

.blog-detail__body h3 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 18px;
  line-height: 1.8rem;
}

@media (min-width: 768px) {
  .blog-detail__body h3 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 20px;
    line-height: 2rem;
  }
}

.blog-detail__body h4 {
  font-size: 16px;
  font-size: 1.6rem;
}

.blog-detail__info {
  position: relative;
  z-index: 11;
  background-color: #fff;
  padding: 0px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

@media (min-width: 768px) {
  .blog-detail__info {
    width: auto;
    margin-left: -100px;
    padding: 40px;
    margin-top: 150px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .blog-detail__info {
    padding: 80px;
    padding-bottom: 0px;
    margin-top: 250px;
  }
}

.pagination {
  text-align: center;
  margin-bottom: 20px;
}

.pagination a {
  margin: 5px 10px;
}

.pagination a.active, .pagination a:hover, .pagination a:focus, .pagination a:active {
  font-weight: 600;
}

.overflow-v.overflow-v {
  overflow: visible;
}

/* =================================================
   ABOUT -- HISTORY
   ================================================= */
.timeline-container {
  width: 100%;
  position: relative;
  display: none;
}

@media (min-width: 768px) {
  .timeline-container {
    display: block;
  }
}

.timeline-container:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  z-index: -2;
}

@media (min-width: 768px) {
  .timeline-container:after {
    content: "";
  }
}

.timeline {
  list-style: none;
  margin-left: 0;
  overflow: hidden;
  *zoom: 1;
}

.timeline:before, .timeline:after {
  display: table;
  content: " ";
}

.timeline:after {
  clear: both;
}

@media (min-width: 768px) {
  .timeline {
    overflow: visible;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.timeline li {
  display: inline-block;
  float: left;
}

.timeline li:first-child {
  margin-left: 0;
}

.timeline__event__button {
  background: transparent;
  border: none;
  padding: 0px;
  margin-top: 20px;
  overflow: visible;
}

.timeline__event__button:hover, .timeline__event__button:focus, .timeline__event__button:active {
  background: transparent;
  border: none;
}

.timeline__event__button:after {
  content: none;
}

.timeline__event__circle {
  width: 100%;
  height: 50px;
  display: block;
  position: relative;
  border: none;
  padding: 0 30px;
}

@media (min-width: 768px) {
  .timeline__event__circle {
    width: 30px;
    height: 30px;
  }
}

.timeline__event__circle:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  z-index: -1;
  position: absolute;
  top: 0;
  margin-top: 5px;
}

.timeline__event__circle:after {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  background-color: #fff;
  border: 5px solid #000;
  width: 20px;
  height: 20px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  margin-top: -17.5px;
}

@media (min-width: 768px) {
  .timeline__event__circle:after {
    margin-top: -7.5px;
  }
}

.timeline__event__year {
  float: left;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}

@media (min-width: 768px) {
  .timeline__event__year {
    margin-top: 40px;
    margin-left: 0;
  }
}

.timeline__event__button:hover,
.timeline__event__button:focus,
.timeline__event__button:active,
.timeline__event__button--active {
  outline: none;
  box-shadow: none;
}

.timeline__event__button:hover .timeline__event__circle:after,
.timeline__event__button:focus .timeline__event__circle:after,
.timeline__event__button:active .timeline__event__circle:after,
.timeline__event__button--active .timeline__event__circle:after {
  width: 30px;
  height: 30px;
}

.timeline__event__button:hover .timeline__event__year,
.timeline__event__button:focus .timeline__event__year,
.timeline__event__button:active .timeline__event__year,
.timeline__event__button--active .timeline__event__year {
  font-size: 25px;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .timeline__event__button:hover .timeline__event__year,
  .timeline__event__button:focus .timeline__event__year,
  .timeline__event__button:active .timeline__event__year,
  .timeline__event__button--active .timeline__event__year {
    font-size: 35px;
    font-size: 3.5rem;
  }
}

@media (max-width: 766px) {
  #geschiedenis > h2 {
    margin-bottom: -30px;
  }
}

.history-wrap {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 0;
}

@media (min-width: 768px) {
  .history-wrap {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}

@media (max-width: 766px) {
  .grid-item__history-img {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.history-item__info p {
  *zoom: 1;
  display: inline-block;
  vertical-align: middle;
}

.history-item__info p:before, .history-item__info p:after {
  display: table;
  content: " ";
}

.history-item__info p:after {
  clear: both;
}

.history-item__info img {
  -o-object-fit: contain;
  object-fit: contain;
  font-family: "object-fit: contain";
  height: auto;
}

.history-item__year {
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
}

@media (min-width: 768px) {
  .history-item__year {
    font-size: 18px;
    font-size: 1.8rem;
    display: none;
  }
}

@media (min-width: 768px) {
  .history-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.history-item__visual {
  margin-bottom: 10px;
  width: 100%;
}

@media (min-width: 768px) {
  .history-item__visual {
    margin-bottom: 0;
  }
}

.history-item__visual-bg {
  height: auto;
}

@media (min-width: 768px) {
  .history-item__visual-bg {
    height: 450px;
  }
}

.history-item__link-pager {
  display: block;
  *zoom: 1;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.history-item__link-pager:before, .history-item__link-pager:after {
  display: table;
  content: " ";
}

.history-item__link-pager:after {
  clear: both;
}

@media (min-width: 768px) {
  .history-item__link-pager {
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    width: 60px;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    margin-bottom: -20px;
  }
  .history-item__link-pager:after {
    content: none;
  }
  .history-item__link-pager:hover i, .history-item__link-pager:focus i, .history-item__link-pager:active i {
    margin-left: 20px;
  }
}

.history-item__link-pager i {
  display: none;
  font-size: 10px;
  font-size: 1rem;
  transform: scale(-1);
  float: left;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
}

@media (min-width: 768px) {
  .history-item__link-pager i {
    display: block;
  }
}

.history-item__link-pager span {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

.history-item__link-pager span:before {
  content: "Bekijk ";
  display: inline-block;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .history-item__link-pager span:before {
    content: none;
  }
}

@media (min-width: 768px) {
  .history-item__link-pager span {
    text-align: left;
    display: block;
    font-family: "Cormorant", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
    margin-left: 10px;
  }
}

.history-item__link-prev {
  display: block;
  margin-top: 10px;
  padding: 10px;
  font-size: 13px;
  font-size: 1.3rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .history-item__link-prev {
    display: none;
  }
}

html[lang="nl"] .history-item__link-pager span:before {
  content: "Bekijk ";
}

@media (min-width: 768px) {
  html[lang="nl"] .history-item__link-pager span:before {
    content: none;
  }
}

html[lang="en"] .history-item__link-pager span:before {
  content: "View ";
}

@media (min-width: 768px) {
  html[lang="en"] .history-item__link-pager span:before {
    content: none;
  }
}

/* =================================================
   Contact
   ================================================= */
.contact-visual {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 0;
  padding-bottom: 53%;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .contact-visual {
    margin-top: 0;
    height: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.contact-visual__title {
  padding: 10px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: -60px;
}

@media (min-width: 768px) {
  .contact-visual__title {
    margin-left: 0;
    left: auto;
    right: 0;
    margin-top: 65px;
    padding: 30px 40px;
  }
}

@media (min-width: 768px) {
  .contact-form {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .contact-form {
    margin-top: 100px;
  }
}

.contact-form ul {
  margin-bottom: 0;
}

.contact-form ul li {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact-form ul li {
    margin-bottom: 10px;
  }
}

.contact-form ul li:last-child {
  margin-bottom: 0;
}

.contact-form textarea {
  height: 100px;
}

@media (min-width: 768px) {
  .contact-form textarea {
    margin-bottom: 40px;
  }
}

.contact-form button {
  margin-bottom: 0;
}

/* =================================================
   News articles
   ================================================= */
@media (min-width: 768px) {
  .blog-slideshow__info-wrap {
    max-width: 400px;
  }
}

/* =================================================
   PAGER BLOG ITEMS - HOME & DETAIL BLOG
   ================================================= */
.blog-pager {
  list-style: none;
  text-align: center;
  margin-left: 0;
}

@media (min-width: 480px) {
  .blog-pager.blog-pager--blog-detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -20px;
  }
}

@media (min-width: 768px) {
  .blog-pager.blog-pager--blog-detail {
    margin-left: 0;
  }
}

.blog-pager.blog-pager--blog-detail .blog-pager__item:not(.blog-pager__item--back) {
  width: 100%;
}

@media (min-width: 768px) {
  .blog-pager.blog-pager--blog-detail .blog-pager__item:not(.blog-pager__item--back) {
    width: auto;
  }
}

.blog-pager__item {
  *zoom: 1;
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

.blog-pager__item:before, .blog-pager__item:after {
  display: table;
  content: " ";
}

.blog-pager__item:after {
  clear: both;
}

.blog-pager__item:not(.blog-pager__item--back) {
  width: 100%;
}

@media (min-width: 480px) {
  .blog-pager__item:not(.blog-pager__item--back) {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .blog-pager__item:not(.blog-pager__item--back) {
    width: auto;
  }
}

.blog-pager__item a {
  display: block;
}

.blog-pager__item a:hover, .blog-pager__item a:focus, .blog-pager__item a:active {
  color: #000;
}

.blog-pager__item--back.blog-pager__item--back {
  clear: left;
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .blog-pager__item--back.blog-pager__item--back {
    float: right;
    clear: none;
    text-align: right;
  }
}

.blog-pager__item--back.blog-pager__item--back .blog-pager__item__arrow-wrap {
  width: auto;
}

.blog-pager__item--back.blog-pager__item--back .blog-pager__item__arrow-wrap i,
.blog-pager__item--back.blog-pager__item--back .blog-pager__item__arrow-wrap span {
  display: inline-block;
}

@media (min-width: 768px) {
  .blog-pager__item--back.blog-pager__item--back .blog-pager__item__arrow-wrap i,
  .blog-pager__item--back.blog-pager__item--back .blog-pager__item__arrow-wrap span {
    display: block;
  }
}

.blog-pager--blog-detail .blog-pager__item--prev .blog-pager__item__arrow-wrap i {
  margin-right: 50px;
}

.blog-pager--blog-detail .blog-pager__item--next .blog-pager__item__arrow-wrap i {
  margin-left: 40px;
}

.blog-pager__item__arrow-wrap {
  width: 250px;
  display: none;
  *zoom: 1;
}

@media (min-width: 980px) {
  .blog-pager__item__arrow-wrap {
    display: block;
  }
}

.blog-pager__item__arrow-wrap:before, .blog-pager__item__arrow-wrap:after {
  display: table;
  content: " ";
}

.blog-pager__item__arrow-wrap:after {
  clear: both;
}

.blog-pager__item__arrow-wrap i {
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  display: block;
  margin-bottom: 5px;
  font-size: 8px;
  font-size: 0.8rem;
}

.blog-pager__item__arrow-wrap span {
  margin-bottom: 25px;
  display: block;
}

.blog-pager__item__arrow-wrap--home {
  width: auto;
}

.blog-pager__item--prev {
  text-align: left;
}

.blog-pager__item--prev span {
  margin-left: 10px;
}

.blog-pager__item--next i {
  transform: rotate(180deg) translate(0, 0);
  float: left;
}

.blog-pager__item--next span {
  clear: left;
}

.blog-pager__item__content {
  clear: both;
  position: relative;
}

@media (min-width: 480px) {
  .blog-pager__item__content {
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .blog-pager__item__content {
    padding-left: 0;
    width: 300px;
  }
}

.blog-pager__item__visual {
  height: 170px;
  width: 100%;
  display: inline-block;
}

.blog-pager__item__visual:last-child {
  margin-right: 0;
}

@media (min-width: 1024px) {
  .blog-pager__item__visual {
    width: 250px;
  }
}

.blog-pager__item__header {
  position: relative;
  text-align: left;
  display: block;
  margin-left: auto;
  z-index: 11;
  margin-right: auto;
  padding: 15px 20px;
  background-color: #fff;
  width: 80%;
  margin-top: -40px;
  cursor: pointer;
  font-family: "harmansimple", Museo Sans, Helvetica, Arial, Verdana, sans-serif;
}

@media (min-width: 1024px) {
  .blog-pager__item__header {
    max-height: 120px;
    position: absolute;
    left: 0;
    margin-left: 150px;
    top: 0;
    margin-top: 30px;
    width: 250px;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .blog-pager__item__header--home {
    width: 150px;
  }
}

@media (min-width: 1200px) {
  .blog-pager__item__header--home {
    width: 200px;
  }
}

@media (min-width: 1300px) {
  .blog-pager__item__header--home {
    width: 250px;
    margin-right: 40px;
  }
}

.blog-pager--blog-detail .blog-pager__item--prev {
  float: left;
}

.blog-pager--blog-detail .blog-pager__item--prev .blog-pager__item__arrow-wrap {
  text-align: right;
  float: right;
}

@media (min-width: 768px) {
  .blog-pager--blog-detail .blog-pager__item--prev .blog-pager__item__arrow-wrap {
    margin-right: 50px;
  }
}

.blog-pager--blog-detail .blog-pager__item--prev .blog-pager__item__arrow-wrap i {
  margin-left: 20px;
}

@media (min-width: 1024px) {
  .blog-pager--blog-detail .blog-pager__item--next {
    margin-right: 150px;
  }
}

.blog-pager--blog-detail .blog-pager__item--next .blog-pager__item__arrow-wrap {
  text-align: left;
  margin-left: 30px;
}

.blog-pager--blog-detail .blog-pager__item--next .blog-pager__item__arrow-wrap i {
  margin-left: 20px;
}

.news-nav--prev {
  float: left;
}

.news-nav--next {
  float: right;
}

@media (min-width: 768px) {
  .news-nav--next {
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .news-nav--next {
    margin-right: 0;
  }
}

.news-nav--next i {
  float: none;
}

@media (min-width: 768px) {
  .news-nav--next i {
    margin-left: 30px;
  }
}

@media (min-width: 768px) {
  html[lang="en"] .news-nav--next i {
    margin-left: 30px;
  }
}

@media (min-width: 768px) {
  html[lang="nl"] .news-nav--next i {
    margin-left: 50px;
  }
}

.news-nav__item.left {
  left: 0;
}

.news-nav__item.right {
  right: 0;
}

.news-nav {
  position: relative;
  z-index: 12;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  *zoom: 1;
  user-select: none;
  text-align: center;
  margin-top: 20px;
  float: left;
}

.news-nav:before, .news-nav:after {
  display: table;
  content: " ";
}

.news-nav:after {
  clear: both;
}

.news-nav .news-nav__centered-button {
  padding-left: 20px;
}

@media (min-width: 768px) {
  .news-nav .news-nav__centered-button {
    padding-left: 0;
  }
}

.news-nav .blog-pager__item {
  cursor: pointer;
}

@media (min-width: 768px) {
  .news-nav {
    margin-left: 20px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .news-nav {
    display: block;
  }
}

@media (min-width: 1200px) {
  .news-nav {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: none;
    position: absolute;
    margin-bottom: 10px;
    bottom: 0;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    width: calc(100% + 135px);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -15px;
  }
}

@media (min-width: 1300px) {
  .news-nav {
    width: calc(100% + 135px);
    margin-left: -15px;
  }
}

@media (min-width: 1400px) {
  .news-nav {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .news-nav .blog-pager__item__arrow-wrap {
    display: inline-block;
  }
}

@media (min-width: 1680px) {
  .news-nav {
    margin-top: -10px;
  }
}

.news-nav .blog-pager__item {
  margin-top: 0px;
}

.news-nav .blog-pager__item span {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .news-nav__centered-button {
    display: inline-block;
    align-self: center;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .news-nav__centered-button {
    display: none;
    margin-bottom: 0;
  }
}

.news-article-wrapper {
  overflow: visible;
  max-width: 1200px;
  z-index: 9;
}

@media (min-width: 768px) {
  .news-article-wrapper {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .news-article-wrapper {
    width: 85%;
  }
}

@media (min-width: 1680px) {
  .news-article-wrapper {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .news-article-wrapper .h4 {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -30px;
  }
}

.news-article-wrapper,
.h4 {
  margin-left: auto;
  margin-right: auto;
}

.news-article {
  width: 100%;
  height: 100%;
  top: 0;
  left: 40px;
  transition: all 0.7s linear;
  opacity: 0;
  z-index: 0;
  height: 0;
}

@media (min-width: 768px) {
  .news-article {
    position: absolute;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .news-article p {
    max-height: 140px;
    margin-bottom: 20px;
  }
}

.js-news-article--show {
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 1;
  height: auto;
}

.js-news-article--viewed-left,
.js-news-article--viewed-right {
  transition: all 0.3s;
}

.js-news-article--viewed-left {
  left: -250px;
}

.js-news-article--viewed-right {
  left: 250px;
}

.news-article-previews {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 40px;
  display: none;
  margin-left: 0;
}

@media (min-width: 1024px) {
  .news-article-previews {
    display: block;
  }
}

.news-article-previews__slider {
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-out;
}

.news-article-previews__slider--no-animation {
  transition: none;
}

.news-article-preview {
  position: absolute;
  transition: all 0.7s ease-out;
  padding-right: 30px;
  margin-top: 0;
}

.news-article-preview--no-animation {
  transition: none;
}

.js-news-article-preview--active {
  opacity: 1;
}

.js-news-article-preview--active .blog-pager__item__content {
  opacity: 1;
}

.location-grid {
  position: relative;
}

.location-grid .products-collection-visual__title {
  padding: 15px;
}

.location-grid .products-collection-visual__title--left {
  top: auto;
  bottom: 0;
  margin-bottom: 55px;
}

.location-info {
  margin-top: 75px;
}

.location-info a {
  line-height: 1.5em;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }
  html {
    font-size: 10px;
  }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  .page-footer,
  .main-nav {
    display: none;
  }
  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
