/*============================================================================
  VARIABLES
==============================================================================*/

// Grays
$black: #000;
$grayDark: #777;
$gray: #b4b4b4;
$grayMiddle: #d2cecb;
$grayLight: #d5d5d5;
$white: #fff;

$accentGrey: #e3e3e3;
$accentDark: #9e4d20;
// Accent colors
$accent: #faecd8;
$accentHover: darken($accent, 10%);

$accentLight: lighten($accent, 15%);

// Links
$linkColor: $black;
$linkColorHover: $grayDark;

// States
$error: #f1062c;
$errorBg: lighten($error, 40%);

$success: #39963a;
$successBg: lighten($success, 55%);

// Selection
$selection: darken($accent, 5%);

// Easing
$easeLinear: cubic-bezier(0, 0, 1, 1);
$easeIn: cubic-bezier(0.42, 0, 1, 1);
$easeOut: cubic-bezier(0, 0, 0.58, 1);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);

// Transitions
$transitionDefault: 0.25s $easeOut;

// Sizes
$docFontSize: 10;

// Grid
$gutter: 20px !default;

// Would you like push and pull classes enabled?
$push: true !default;
$pull: false !default;

// Mediaqueries
// If you want more breakpoints, add a nummeric suffix (e.g huge-2)
$xsmallSize: 400px;
$smallSize: 480px;
$mediumSize: 768px;
$desktopSize: 980px;
$largeSize: 1024px;
$largerSize: 1200px;
$wideSize: 1300px;
$hugeSize: 1400px;
$massiveSize: 1680px;

$xsmall: "(min-width: #{$xsmallSize})";
$small: "(min-width: #{$smallSize})";
$medium: "(min-width: #{$mediumSize})";
$desktop: "(min-width: #{$desktopSize})";
$large: "(min-width: #{$largeSize})";
$larger: "(min-width: #{$largerSize})";
$huge: "(min-width: #{$hugeSize})";
$wide: "(min-width: #{$wideSize})";
$massive: "(min-width: #{$massiveSize})";

// Mobile mediaqueries
$maxMedium: "(max-width: 766px)";

// Define your breakpoints. The first value is the prefix that shall be used for
// your classes (e.g. `.medium--one-half`), the second value is the media query
// that the breakpoint fires at.
$breakpoints: (
  "small" "#{$small}",
  "medium" "#{$medium}",
  "large" "#{$large}",
  "larger" "#{$larger}",
  "wide" "#{$wide}",
  "huge" "#{$huge}",
  "massive" "#{$massive}"
) !default;

// Define which namespaced breakpoints you would like to generate for each of
// widths, push and pull. This is handy if you only need pull on, say, large, or
// you only need a new width breakpoint at mobile sizes. It allows you to only
// compile as much CSS as you need.
$breakpoint-has-widths: ("small", "medium", "large", "larger") !default;
$breakpoint-has-push: ("medium", "large") !default;
$breakpoint-has-pull: ("medium") !default;

// Init mediaqueries for ordering inside carabiner
.init-mediaqueries {
  @media #{$small} {
    margin: 0;
  }
  @media #{$medium} {
    margin: 0;
  }
  @media #{$large} {
    margin: 0;
  }
  @media #{$larger} {
    margin: 0;
  }
  @media #{$huge} {
    margin: 0;
  }
  @media #{$massive} {
    margin: 0;
  }
}
