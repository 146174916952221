/*============================================================================
  GRID
==============================================================================*/

// Mixins
// For the library to use only, you should not need to modify them at all.
@mixin grid-media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query == $name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn "Breakpoint '#{$media-query}' does not exist";
  }
}

@mixin silent-relative() {
  position: relative;
}

// Grid setup
.grid {
  @include clearfix;
  margin: 0 0 20px -#{$gutter};
}

ul.grid,
ul.grid-uniform {
  list-style: none;
  padding: 0;
}

.grid__item {
  float: left;
  padding-left: $gutter;
  width: 100%;
}

// Grid reversed
.grid--rev {
  @extend .grid;
  direction: rtl;
  text-align: left;

  > .grid__item {
    direction: ltr;
    text-align: left;
    float: right;
  }
}

// Gutterless grid
.grid--full {
  @extend .grid;
  margin-left: 0;

  > .grid__item {
    padding-left: 0;
  }
}

// Grid gutter exceptions
.grid--g40 {
  @extend .grid;
  margin-left: -40px;

  > .grid__item {
    padding-left: 40px;
  }
}
.grid--g50 {
  @extend .grid;
  margin-left: -50px;

  > .grid__item {
    padding-left: 50px;
  }
}
.grid--g80 {
  @extend .grid;
  margin-left: -40px;

  @media #{$larger} {
    margin-left: -80px;
  }

  > .grid__item {
    padding-left: 40px;

    @media #{$larger} {
      padding-left: 80px;
    }
  }
}
.grid--g100 {
  @extend .grid;
  margin-left: -50px;

  @media #{$larger} {
    margin-left: -100px;
  }

  > .grid__item {
    padding-left: 50px;

    @media #{$larger} {
      padding-left: 100px;
    }
  }
}
.grid--g120 {
  @extend .grid;
  margin-left: -60px;

  @media #{$larger} {
    margin-left: -120px;
  }

  > .grid__item {
    padding-left: 60px;

    @media #{$larger} {
      padding-left: 120px;
    }
  }
}

.grid--g160 {
  @extend .grid;
  margin-left: -40px;
  @media #{$medium} {
    margin-left: -80px;
  }
  @media #{$larger} {
    margin-left: -160px;
  }

  > .grid__item {
    padding-left: 40px;

    @media #{$medium} {
      padding-left: 80px;
    }
    @media #{$larger} {
      padding-left: 160px;
    }
  }
}

.grid--justify-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

// Widths
@mixin device-type($namespace: "") {
  // Whole
  .#{$namespace}one-whole {
    width: 100%;
  }

  // Halves
  .#{$namespace}one-half {
    width: 50%;
  }

  // Thirds
  .#{$namespace}one-third {
    width: 33.333%;
  }
  .#{$namespace}two-thirds {
    width: 66.666%;
  }

  // Quarters
  .#{$namespace}one-quarter {
    width: 25%;
  }
  .#{$namespace}two-quarters {
    width: 50%;
  }
  .#{$namespace}three-quarters {
    width: 75%;
  }

  // Fifths
  .#{$namespace}one-fifth {
    width: 20%;
  }
  .#{$namespace}two-fifths {
    width: 40%;
  }
  .#{$namespace}three-fifths {
    width: 60%;
  }
  .#{$namespace}four-fifths {
    width: 80%;
  }

  // Sixths
  .#{$namespace}one-sixth {
    width: 16.666%;
  }
  .#{$namespace}two-sixths {
    width: 33.333%;
  }
  .#{$namespace}three-sixths {
    width: 50%;
  }
  .#{$namespace}four-sixths {
    width: 66.666%;
  }
  .#{$namespace}five-sixths {
    width: 83.333%;
  }

  // Eighths
  .#{$namespace}one-eighth {
    width: 12.5%;
  }
  .#{$namespace}two-eighths {
    width: 25%;
  }
  .#{$namespace}three-eighths {
    width: 37.5%;
  }
  .#{$namespace}four-eighths {
    width: 50%;
  }
  .#{$namespace}five-eighths {
    width: 62.5%;
  }
  .#{$namespace}six-eighths {
    width: 75%;
  }
  .#{$namespace}seven-eighths {
    width: 87.5%;
  }

  // Tenths
  .#{$namespace}one-tenth {
    width: 10%;
  }
  .#{$namespace}two-tenths {
    width: 20%;
  }
  .#{$namespace}three-tenths {
    width: 30%;
  }
  .#{$namespace}four-tenths {
    width: 40%;
  }
  .#{$namespace}five-tenths {
    width: 50%;
  }
  .#{$namespace}six-tenths {
    width: 60%;
  }
  .#{$namespace}seven-tenths {
    width: 70%;
  }
  .#{$namespace}eight-tenths {
    width: 80%;
  }
  .#{$namespace}nine-tenths {
    width: 90%;
  }

  // Twelfths
  .#{$namespace}one-twelfth {
    width: 8.333%;
  }
  .#{$namespace}two-twelfths {
    width: 16.666%;
  }
  .#{$namespace}three-twelfths {
    width: 25%;
  }
  .#{$namespace}four-twelfths {
    width: 33.333%;
  }
  .#{$namespace}five-twelfths {
    width: 41.666%;
  }
  .#{$namespace}six-twelfths {
    width: 50%;
  }
  .#{$namespace}seven-twelfths {
    width: 58.333%;
  }
  .#{$namespace}eight-twelfths {
    width: 66.666%;
  }
  .#{$namespace}nine-twelfths {
    width: 75%;
  }
  .#{$namespace}ten-twelfths {
    width: 83.333%;
  }
  .#{$namespace}eleven-twelfths {
    width: 91.666%;
  }
}

// Device helper
@mixin device-helper($namespace: "") {
  .#{$namespace}show {
    display: block !important;
  }
  .#{$namespace}hide {
    display: none !important;
  }

  .#{$namespace}text-left {
    text-align: left !important;
  }
  .#{$namespace}text-right {
    text-align: right !important;
  }
  .#{$namespace}text-center {
    text-align: center !important;
  }

  .#{$namespace}left {
    float: left !important;
  }
  .#{$namespace}right {
    float: right !important;
  }
}

// Regular, non-responsive width classes
@include device-type();
@include device-helper();

// Responsive classes
@each $name in $breakpoint-has-widths {
  @include grid-media-query($name) {
    @include device-type("#{$name}--");
    @include device-helper("#{$name}--");
  }
}

// Push
@mixin push-setup($namespace: "") {
  // Whole
  .push--#{$namespace}one-whole {
    left: 100%;
    @include silent-relative();
  }

  // Halves
  .push--#{$namespace}one-half {
    left: 50%;
    @include silent-relative();
  }

  // Thirds
  .push--#{$namespace}one-third {
    left: 33.333%;
    @include silent-relative();
  }
  .push--#{$namespace}two-thirds {
    left: 66.666%;
    @include silent-relative();
  }

  // Quarters
  .push--#{$namespace}one-quarter {
    left: 25%;
    @include silent-relative();
  }
  .push--#{$namespace}two-quarters {
    left: 50%;
    @include silent-relative();
  }
  .push--#{$namespace}three-quarters {
    left: 75%;
    @include silent-relative();
  }

  // Fifths
  .push--#{$namespace}one-fifth {
    left: 20%;
    @include silent-relative();
  }
  .push--#{$namespace}two-fifths {
    left: 40%;
    @include silent-relative();
  }
  .push--#{$namespace}three-fifths {
    left: 60%;
    @include silent-relative();
  }
  .push--#{$namespace}four-fifths {
    left: 80%;
    @include silent-relative();
  }

  // Sixths
  .push--#{$namespace}one-sixth {
    left: 16.666%;
    @include silent-relative();
  }
  .push--#{$namespace}two-sixths {
    left: 33.333%;
    @include silent-relative();
  }
  .push--#{$namespace}three-sixths {
    left: 50%;
    @include silent-relative();
  }
  .push--#{$namespace}four-sixths {
    left: 66.666%;
    @include silent-relative();
  }
  .push--#{$namespace}five-sixths {
    left: 83.333%;
    @include silent-relative();
  }

  // Eighths
  .push--#{$namespace}one-eighth {
    left: 12.5%;
    @include silent-relative();
  }
  .push--#{$namespace}two-eighths {
    left: 25%;
    @include silent-relative();
  }
  .push--#{$namespace}three-eighths {
    left: 37.5%;
    @include silent-relative();
  }
  .push--#{$namespace}four-eighths {
    left: 50%;
    @include silent-relative();
  }
  .push--#{$namespace}five-eighths {
    left: 62.5%;
    @include silent-relative();
  }
  .push--#{$namespace}six-eighths {
    left: 75%;
    @include silent-relative();
  }
  .push--#{$namespace}seven-eighths {
    left: 87.5%;
    @include silent-relative();
  }

  // Tenths
  .push--#{$namespace}one-tenth {
    left: 10%;
    @include silent-relative();
  }
  .push--#{$namespace}two-tenths {
    left: 20%;
    @include silent-relative();
  }
  .push--#{$namespace}three-tenths {
    left: 30%;
    @include silent-relative();
  }
  .push--#{$namespace}four-tenths {
    left: 40%;
    @include silent-relative();
  }
  .push--#{$namespace}five-tenths {
    left: 50%;
    @include silent-relative();
  }
  .push--#{$namespace}six-tenths {
    left: 60%;
    @include silent-relative();
  }
  .push--#{$namespace}seven-tenths {
    left: 70%;
    @include silent-relative();
  }
  .push--#{$namespace}eight-tenths {
    left: 80%;
    @include silent-relative();
  }
  .push--#{$namespace}nine-tenths {
    left: 90%;
    @include silent-relative();
  }

  // Twelfths
  .push--#{$namespace}one-twelfth {
    left: 8.333%;
    @include silent-relative();
  }
  .push--#{$namespace}two-twelfths {
    left: 16.666%;
    @include silent-relative();
  }
  .push--#{$namespace}three-twelfths {
    left: 25%;
    @include silent-relative();
  }
  .push--#{$namespace}four-twelfths {
    left: 33.333%;
    @include silent-relative();
  }
  .push--#{$namespace}five-twelfths {
    left: 41.666%;
    @include silent-relative();
  }
  .push--#{$namespace}six-twelfths {
    left: 50%;
    @include silent-relative();
  }
  .push--#{$namespace}seven-twelfths {
    left: 58.333%;
    @include silent-relative();
  }
  .push--#{$namespace}eight-twelfths {
    left: 66.666%;
    @include silent-relative();
  }
  .push--#{$namespace}nine-twelfths {
    left: 75%;
    @include silent-relative();
  }
  .push--#{$namespace}ten-twelfths {
    left: 83.333%;
    @include silent-relative();
  }
  .push--#{$namespace}eleven-twelfths {
    left: 91.666%;
    @include silent-relative();
  }
}

@if $push == true {
  [class*="push--"] {
    position: relative;
  }

  @include push-setup();

  @each $name in $breakpoint-has-push {
    @include grid-media-query($name) {
      @include push-setup("#{$name}--");
    }
  }
}

// Pull
@mixin pull-setup($namespace: "") {
  // Whole
  .pull--#{$namespace}one-whole {
    right: 100%;
    @include silent-relative();
  }

  // Halves
  .pull--#{$namespace}one-half {
    right: 50%;
    @include silent-relative();
  }

  // Thirds
  .pull--#{$namespace}one-third {
    right: 33.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-thirds {
    right: 66.666%;
    @include silent-relative();
  }

  // Quarters
  .pull--#{$namespace}one-quarter {
    right: 25%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-quarters {
    right: 50%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-quarters {
    right: 75%;
    @include silent-relative();
  }

  // Fifths
  .pull--#{$namespace}one-fifth {
    right: 20%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-fifths {
    right: 40%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-fifths {
    right: 60%;
    @include silent-relative();
  }
  .pull--#{$namespace}four-fifths {
    right: 80%;
    @include silent-relative();
  }

  // Sixths
  .pull--#{$namespace}one-sixth {
    right: 16.666%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-sixths {
    right: 33.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-sixths {
    right: 50%;
    @include silent-relative();
  }
  .pull--#{$namespace}four-sixths {
    right: 66.666%;
    @include silent-relative();
  }
  .pull--#{$namespace}five-sixths {
    right: 83.333%;
    @include silent-relative();
  }

  // Eighths
  .pull--#{$namespace}one-eighth {
    right: 12.5%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-eighths {
    right: 25%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-eighths {
    right: 37.5%;
    @include silent-relative();
  }
  .pull--#{$namespace}four-eighths {
    right: 50%;
    @include silent-relative();
  }
  .pull--#{$namespace}five-eighths {
    right: 62.5%;
    @include silent-relative();
  }
  .pull--#{$namespace}six-eighths {
    right: 75%;
    @include silent-relative();
  }
  .pull--#{$namespace}seven-eighths {
    right: 87.5%;
    @include silent-relative();
  }

  // Tenths
  .pull--#{$namespace}one-tenth {
    right: 10%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-tenths {
    right: 20%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-tenths {
    right: 30%;
    @include silent-relative();
  }
  .pull--#{$namespace}four-tenths {
    right: 40%;
    @include silent-relative();
  }
  .pull--#{$namespace}five-tenths {
    right: 50%;
    @include silent-relative();
  }
  .pull--#{$namespace}six-tenths {
    right: 60%;
    @include silent-relative();
  }
  .pull--#{$namespace}seven-tenths {
    right: 70%;
    @include silent-relative();
  }
  .pull--#{$namespace}eight-tenths {
    right: 80%;
    @include silent-relative();
  }
  .pull--#{$namespace}nine-tenths {
    right: 90%;
    @include silent-relative();
  }

  // Twelfths
  .pull--#{$namespace}one-twelfth {
    right: 8.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}two-twelfths {
    right: 16.666%;
    @include silent-relative();
  }
  .pull--#{$namespace}three-twelfths {
    right: 25%;
    @include silent-relative();
  }
  .pull--#{$namespace}four-twelfths {
    right: 33.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}five-twelfths {
    right: 41.666%;
    @include silent-relative();
  }
  .pull--#{$namespace}six-twelfths {
    right: 50%;
    @include silent-relative();
  }
  .pull--#{$namespace}seven-twelfths {
    right: 58.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}eight-twelfths {
    right: 66.666%;
    @include silent-relative();
  }
  .pull--#{$namespace}nine-twelfths {
    right: 75%;
    @include silent-relative();
  }
  .pull--#{$namespace}ten-twelfths {
    right: 83.333%;
    @include silent-relative();
  }
  .pull--#{$namespace}eleven-twelfths {
    right: 91.666%;
    @include silent-relative();
  }
}

@if $pull == true {
  [class*="pull--"] {
    position: relative;
  }

  @include pull-setup();

  @each $name in $breakpoint-has-pull {
    @include grid-media-query($name) {
      @include pull-setup("#{$name}--");
    }
  }
}
