.md-modal {
  position: fixed; top: 50%; left: 50%; width: 50%; z-index: 999999;
  min-width: 320px; height: auto; visibility: hidden; backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  text-align: left; font-size: 18px;

  @media #{$maxMedium} {
    display: none;
  }

  @media #{$large} {
    width: 940px;
  }

  &.mobile {
    width: 100%;
    height: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.md-modal.md-cover {
  top: 0; left: 0; width: 100%;
  transform: none;

  .md-content {
    height: 100%; height: 100vh; overflow-y: scroll;
    transform: scale(0.9);
    backface-visibility: hidden;
    padding: 20px 25px;
  }

  .md-close {
    position: fixed; z-index: 1000;
    top: 25px;
    right: 25px;
    opacity: 0;
    transform: translateY(20%);
    padding: 0 5px;
  }

  &.md-show {
    .md-close {
      opacity: 1;
      transform: translateY(0);
      .text {
        color: $black;
      }
      &:hover, &:focus {
        .text {
        }
        .icon-close {
          color: $black;
        }
      }
    }
    .md-content {
      transform: scale(1.0);
    }
  }

  @media #{$medium} {
    .md-close {
      top: 15px; right: 40px;
    }
  }
}

.md-cover-dynamic {
  @extend .md-cover;
  .md-content.md-content {
    padding-top: 35px;
  }
}

.md-content {
  @include clearfix; position: relative; margin: 0 auto; padding: 30px;
  background: $white; opacity: 0; transition: $transitionDefault;
  transform: translateY(20%);
  //color: $white;



  a {
    //color: $accent;
    //&:hover, &:focus { color: $accent; }
  }

  .md-close, .md-close-holder {
    position: absolute;
    top: -25px;
    right: -5px;
  }

  @media #{$large} {
    padding: 60px;
  }
}

.md-show {
  visibility: visible; pointer-events: auto;

  .md-content {
    opacity: 1;
    transform: translateY(0);
  }

  ~ .md-overlay {
    opacity: 1; visibility: visible;
  }

  @media #{$maxMedium} {
    display: block;
  }
}

// add close icon
.md-close {
  text-align: center; padding: 0; font-size: 18px;
  //color: $white;
  background-color: transparent;
  border: none;
  &:after, &:before {
    display: none;
  }
  .text {
    display: inline;
    padding: 0;
    background-color:e transparent;
    @include default-font();
    font-size: 10px;
    text-transform: uppercase;
    position: relative;
    top: -4px;
    right: 0px;
  }
  .icon-close {
    color: $accent;
    transition: $transitionDefault;
    margin-left: 3px;
  }
  &:hover, &:focus {
    background-color: transparent;
    .text {
      color: $white;
    }
    .icon-close {
      color: $white;
    }
  }
}

.md-overlay {
  position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 1000;
  visibility: hidden; opacity: 0; background: rgba(0,0,0,0.75); transition: $transitionDefault;
}

.noscroll { overflow: hidden; }
