/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/modal";
//@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;
  overflow: hidden;
  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 30px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--no-margin {
  margin: 0;
  padding: 0;
}

.container--accent {
  padding: 0 40px;

  @media #{$medium} {
    padding: 0 160px;
  }
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 1px solid $black;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: -9px;
  }
  &:after {
    margin-top: 8px;
  }
}

// Exceptions: only add what you need!
.mt0.mt0 {
  margin-top: 0;
}
.mb0.mb0 {
  margin-bottom: 0;
}
// .mb20.mb20 { margin-bottom: 20px; }
.mb40.mb40 {
  margin-bottom: 40px;
}

// .mt0.mt0 { margin-top: 0; }
.mt20.mt20 {
  margin-top: 20px;
}
.mt40.mt40 {
  margin-top: 40px;
}

.mt60.mt60 {
  margin-top: 30px;
  @media #{$medium} {
    margin-top: 60px;
  }
}

.mobile--hide.mobile--hide {
  @media #{$maxMedium} {
    display: none;
  }
}
.mobile--mb0.mobile--mb0 {
  @media #{$maxMedium} {
    margin-bottom: 0;
  }
}

@media #{$medium} {
  .medium--mt40 {
    margin-top: 40px;
  }
}
@media #{$medium} {
  .medium--mt60 {
    margin-top: 60px;
  }
}

.hide--larger {
  @media #{$larger} {
    display: none !important;
  }
}
.hide--large {
  @media #{$large} {
    display: none !important;
  }
}

.show--larger {
  display: none;
  @media #{$larger} {
    display: block !important;
  }
}

.show--large {
  display: none;
  @media #{$large} {
    display: block !important;
  }
}

// .ttu { text-transform: uppercase; }
.ttl {
  text-transform: lowercase;
}
.tac {
  text-align: center;
}

.dib.dib {
  display: inline-block;
}

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

@media #{$medium} {
  .medium--equal-h {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;

    > * {
      display: flex;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

@media #{$small} {
  .small--equal-h {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;

    > * {
      display: flex;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

html.no-flexbox {
  .equal-h,
  .medium--equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 5px 0;
  padding-bottom: 0;
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);

  @media #{$medium} {
    float: left;
    padding: 20px 0;
  }

  img {
    display: block;
    width: 150px;
    @media #{$medium} {
      width: 150px;
    }
    @media #{$large} {
      width: 150px;
    }
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }
}

.main-nav {
  text-transform: lowercase;
  @include optimize-font;
  color: $black;
  margin-top: 20px;
  @include brown;

  a {
    text-decoration: none;
    color: $black;
    padding: 12px 15px;
    display: block;

    &:after {
      display: block;
      content: attr(data-title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  a:hover,
  a:focus,
  li.active > a {
    font-weight: bold;
  }

  @media #{$medium} {
    margin: 40px 0 0 40px;
    border-bottom: none;
    float: right;

    li {
      float: left;
      margin: 0 0 0 35px;
    }

    a {
      border-bottom: 0;
      padding: 0;
    }
  }

  @media #{$desktop} {
    li {
      margin-left: 50px;
    }
  }

  @media #{$large} {
    li {
      margin-left: 75px;
    }
  }
}
//nu 2x hetzelfde woord erboven
html[lang="en"],
html[lang="nl"] {
  .main-nav__wrap__li--with-subnav ul {
    @media #{$medium} {
      //+20px komt vn breedte woord erboven/2
      transform: translateX(calc(-50% + 21px));
    }
  }
}

.page-header__main-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  display: none;

  li {
  }
  a {
    @include fs(14);
    flex-grow: 1;
    border: 1px solid $grayLight;
    text-align: center;
    display: block;
    padding: 10px;
    &:first-child {
      border-right: none;
    }
  }
  @media #{$medium} {
    display: none;
  }
}
.main-nav__wrap__li--with-subnav {
  position: relative;
  z-index: 999;

  ul {
    //display: none;
    margin-bottom: 0;
    margin-left: 20px;
    @media #{$medium} {
      display: block;
      margin-left: 0;
      position: absolute;
      top: 0;
      margin-top: 15px;
      text-align: center;
      transform: translateX(-30%);
      max-height: 0;
      transition: 0.3s;
      overflow: hidden;

      &:before {
        content: "";
        margin: 0 auto;
        margin-top: 15px;
        height: 18px;
        width: 1px;
        background-color: $black;
      }
    }
  }

  li {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0;
    float: none;

    a {
      font-weight: 200;
      white-space: nowrap;
    }
  }

  &.active,
  &:hover,
  &:focus,
  &:active {
    ul {
      max-height: 200px;
    }
  }
}

.lang-wrap.lang-wrap {
  position: relative;
  margin-top: -4px;

  @media #{$medium} {
    height: 28px;
  }

  &.divider {
    &:before {
      display: none;
      @media #{$medium} {
        display: inline-block;
      }
    }
  }

  i {
    display: none;
    margin-left: 10px;
    @include fs(10);
    margin-top: 4px;
    transition: $transitionDefault;

    @media #{$medium} {
      display: inline;
      margin-left: 50px;
    }
  }

  &.active {
  }

  &:hover {
    .lang-selector {
      max-height: 100px;
    }

    i {
      transform: rotate(180deg);
    }
  }
  .lang-selector {
    margin-left: 0px;
    transition: $transitionDefault;
    margin-top: 20px;
    margin-bottom: 0;

    @media #{$medium} {
      position: absolute;
      top: 0;
      left: 80px;
      margin-left: 0px;
      max-height: 28px;
      margin-bottom: 0;
      overflow: hidden;
      margin-top: 5px;
    }

    li {
      margin-bottom: 0;

      float: none;
      //
      //border-right: 1px solid $grayLight;
      padding: 0;
      float: left;
      display: flex;
      align-items: center;
      @include fs(18);
      @media #{$medium} {
        @include fs(16);
        display: inline-block;
        float: none;
        padding: 5px 10px;
      }

      &:first-child {
        &:after {
          content: "";
          width: 40px;
          height: 1px;
          background-color: $grayDark;
          margin: 0 7px;
          @media #{$medium} {
            content: none;
          }
        }
      }

      a {
        margin: 0;
        display: inline-block;
        border-bottom: none;
      }

      &:last-child {
        border-right: 0;
      }

      @media #{$medium} {
        padding: 0;
        display: block;
        margin-left: 0;
        border-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.main-nav__trigger {
  display: block;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* position: absolute; top: 15px; right: 20px;
background-color: transparent;position: relative; padding: 12px 20px 12px 35px;
  height: 65px;
  width: 65px;*/

  border: none;
  // &.scroll-with {
  position: fixed;
  background-color: $white;
  top: 15px;
  right: 15px;
  z-index: 99999;
  height: auto;
  width: auto;
  padding: 22px 20px 22px 35px;
  //border: 1px solid $grayLight;
  //box-shadow: -2px 2px 5px 0px rgba(0,0,0,0.3);

  .icon-hamburger {
    top: 25px;
  }

  // }

  &:after {
    content: none;
    @media #{$medium} {
      content: "";
    }
  }

  .icon-hamburger {
    position: absolute;
    left: 10px;
    //top: 18px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  @media #{$medium} {
    margin: 0 0 0 -20px;
    display: block;
  }

  > li {
    &:first-child {
      @media #{$medium} {
        display: none;
      }
    }
  }
}
.md-modal .main-nav__wrap {
  display: block;
  margin-top: 20px;

  li {
    @include fs(22);
    @include lh(26);
    a {
      display: inline-block;
    }

    &.main-nav__wrap__li--with-subnav {
      li {
        @include fs(18);
        @include lh(22);
      }
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */
//#pagefooter main
.main {
  padding: 0;

  @media #{$medium} {
    padding: 50px 0;
    padding-bottom: 130px;
  }

  @media #{$larger} {
    padding-bottom: 100px;
  }

  /*@media #{$huge} {
    padding-bottom: 120px;
  }*/

  @media #{$wide} {
    padding-bottom: 80px;
  }
}

.home .main,
.about .main {
  padding-top: 0;
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  @include fs(14);

  a {
    @include lh(20);
  }

  width: 100%;

  > .container {
    @include clearfix;

    /*    @media #{$large} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }*/

    @media #{$huge} {
      display: flex;
      //justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    @include clearfix;

    li {
      // display: inline-block;
      float: left;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      &.divider {
        margin-left: 0;
        &:first-child {
          margin-left: 5px;
        }
      }
      &.divider:before {
        margin-left: 0px;
        margin-right: 10px;
      }

      &.small-margin {
        margin-left: 5px;
        &.divider:before {
          margin-right: 5px;
        }
      }
    }
  }

  .grid {
    margin-bottom: 0;
  }
  //#pagefooter heights

  margin-bottom: 20px;

  @media #{$medium} {
    /* height: 75px;*/
    margin: 0;
    z-index: 5;

    height: 130px;
    margin-top: -110px;
  }

  @media #{$large} {
    height: 100px;
    margin-top: -80px;
  }

  @media #{$larger} {
    height: 80px;
    margin-top: -50px;
  }

  /*  @media #{$wide} {
    height: 30px;
  //  margin-top: -50px;
  }*/

  @media #{$huge} {
    height: 60px;
    margin-top: -40px;
  }
}

.page-footer__side {
  &:nth-child(2) {
    @media #{$medium} {
      padding-left: 40px;
    }

    @media #{$large} {
      padding-left: 0;
    }
  }

  @media #{$medium} {
    width: 50%;

    float: left;
  }
  @media #{$large} {
    width: 100%;

    display: inline-block;
    float: none;
  }

  @media #{$larger} {
    //width: auto;
  }
  @media #{$wide} {
    width: auto;
  }

  ul {
    margin-left: 0;
    display: block;
    width: 100%;
    @media #{$medium} {
      width: auto;
    }

    @media #{$large} {
      width: auto;
      display: flex;
      align-items: center;
    }

    li,
    li.small-margin {
      display: inline-block;

      margin: 5px 0;
      width: 100%;

      @media #{$medium} {
        //  width: auto;
      }

      @media #{$large} {
        width: auto;
        display: flex;
        margin: 0 5px;
      }
    }

    .divider {
      display: block;

      &:before {
        display: none;
      }
      @media #{$large} {
        display: flex;

        &:before {
          display: inline-block;
          width: 30px;
        }
      }
    }
  }
}

.page-footer__side--social {
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid $accentGrey;
  flex-wrap: wrap;

  //margin-right: 20px;

  @media #{$medium} {
    padding-top: 0;
    margin-top: 0;
    display: block;
    border: none;
  }

  @media #{$large} {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;

    // width: 100%;
  }

  @media #{$huge} {
    margin-top: 0;

    margin-left: auto;
  }

  /*  @media #{$huge} {

  }*/
  .divider:before {
    margin-right: 20px;
    margin-left: 20px;
    @media #{$large} {
      margin-left: 0;
    }
  }
  > span {
    @media #{$medium} {
      display: block;
      margin-right: 20px;
    }

    @media #{$large} {
      float: left;
      margin-right: 0;
    }
    /* @media #{$wide} {

    }*/
    //margin-right: 20px;
  }

  .divider:before {
    display: flex;
    @media #{$medium} {
      display: none;
    }
    @media #{$large} {
      display: flex;
    }
  }
  ul.social-list {
    width: auto;
    margin-left: 0;
    display: flex;

    @media #{$medium} {
      display: inline-block;
    }
    @media #{$large} {
      margin-left: 20px;
      display: flex;
    }

    /*  @media #{$larger} {
      margin-left: 20px;

    }*/

    li {
      display: inline-block;
      width: auto;
      margin-right: 10px;

      @media #{$maxMedium} {
        @include fs(18);
        margin-right: 20px;
      }
    }
  }
}

.esign-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-left: auto;

  height: 20px;
  margin-top: 3px;
  width: 15px;

  @media #{$small} {
    //margin-left: 20px;
    // margin-top: 4px;
    width: 78px;
  }
  @media #{$medium} {
    width: 115px;
  }
}

a.esign {
  //width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;

  &:hover,
  &:focus {
    opacity: 1;
  }
  background-size: 77px 20px;
  @media #{$small} {
    right: 45px;
  }
  @media #{$medium} {
    background-size: 65px 15px;

    width: 16px;
    position: absolute;
    bottom: 0;
    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
      @media #{$medium} {
        width: 70px;
      }
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

.small-indent {
  margin-left: -20px;
  @media #{$medium} {
    margin-left: -50px;
  }
}

.medium--small-indent {
  margin-left: 0px;
  @media #{$medium} {
    margin-left: -50px;
  }
}
/*.large-indent {
  margin-left:-50px;
}*/

.grid .grid__item.no-padding-indent {
  > .small-indent {
    margin-left: -20px - $gutter;
    @media #{$medium} {
      margin-left: -50px - $gutter;
    }
  }
}

.visual-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;

  img {
    visibility: hidden;
  }

  &.bgsize--contain {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//block link
.overlay-block-link {
  //display: inline!important;
  display: inline-block;
  position: static;
  z-index: 1;
  float: none;
  clear: both;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

.box {
  padding: 10px;
  margin-bottom: 40px;
  ul {
    list-style: none;
  }
  &.success {
    background-color: $successBg;
  }
  &.error {
    background-color: $errorBg;
  }
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

//divider

.divider {
  display: flex;
  align-items: center;
  flex-direction: row;
  //display: inline-block;
  &:before {
    content: "";
    width: 50px;
    height: 1px;
    background-color: $black;
    display: inline-block;
  }
}

.divider--small {
  &:before {
    width: 15px;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

/* =================================================
   HOME
   ================================================= */

.homepage-section {
  margin-bottom: 40px;
  @media #{$medium} {
    margin-bottom: 140px;
  }
}

.homepage-section--accent {
  background-color: $accent;
  margin-bottom: 0;
  padding: 40px 0;
  background-image: url("../images/brushes_2.svg");
  background-size: cover;

  @media #{$medium} {
    padding: 100px 0;
  }
}

.homepage-section--blog {
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.accent-block-grid--center {
  @media #{$medium} {
    display: flex;
    justify-content: center;
  }
}

.accent-block-wrap {
  //margin-bottom: 40px;
  position: relative;

  @media #{$medium} {
    margin-bottom: 40px;
  }

  @media #{$larger} {
    margin-bottom: 200px;
  }
  &:before {
    content: "";
    //overflow: hidden;
    opacity: 0;

    position: absolute;
    top: 0;
    right: 0;
    margin-top: -170px;
    margin-top: -230px;
    width: 66.66%;
    height: calc(100% + 250px);
    padding: 40px;
    background-color: $accent;
    z-index: -1;

    @media #{$medium} {
      opacity: 1;
      height: calc(100% + 175px);
    }

    @media #{$larger} {
      height: calc(100% + 230px);
    }
  }

  .medium--equal-h {
    @media #{$medium} {
      > .grid__item {
        display: block;
      }
    }
  }
}

.accent-block__visual.accent-block__visual--home {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  @media #{$medium} {
    margin-bottom: 0;
  }
  img {
    float: none;
  }
}
.accent-block-wrap--about {
  @media #{$medium} {
    padding-top: 70px;
  }
  @media #{$larger} {
    padding-top: 80px;
  }

  &:before {
    left: 50%;
    right: auto;
    width: 50%;
  }
}

.accent-block__text-wrap.accent-block__text-wrap--about {
  padding-left: 0;
}

.about-body {
  strong {
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: 20px;

    @media #{$medium} {
      margin-bottom: 50px;
    }
  }
}

.accent-block__visual {
  height: auto;
  width: 100%;

  img {
    width: 100%;
  }

  @media #{$medium} {
    height: 100%;
  }
}

.accent-block__visual--about {
  margin-bottom: 20px;
  @media #{$medium} {
    height: 500px;
    margin-bottom: 0;
  }
  @media #{$larger} {
    height: 750px;
  }
}

.accent-block__header--about {
  @media #{$maxMedium} {
    padding-left: 30px;
  }
}

.accent-block__text-wrap {
  padding-left: 0;

  position: relative;
  z-index: 9;

  .medium--small-indent {
    @media #{$medium} {
      margin-left: -90px;
    }
  }

  @media #{$medium} {
    //padding-left: 0;
    padding-left: 20px;
    //padding-right: 20px;
    max-width: 500px;
    padding-right: 40px;
  }
}

.accent-block__text-wrap--left {
  padding-right: 0px;
  /*  padding-left: 40px;

  @media #{$medium} {
    padding-right: 0px;
    padding-left: 60px;
  }*/
}

.accent-block__visual__img {
  @media #{$medium} {
    float: right;
  }
}
.accent-block-wrap--home {
  padding: 0 30px;

  margin-bottom: 40px;

  @media #{$medium} {
    padding: 0;
    margin-bottom: 80px;
  }

  @media #{$wide} {
    margin-bottom: 120px;
  }

  @media #{$huge} {
    margin-bottom: 150px;
  }

  @media #{$massive} {
    > .grid {
      display: flex;
      align-items: center;
    }
  }

  &:before {
    @media #{$medium} {
      height: calc(100% + 200px);
    }
    @media #{$wide} {
      height: calc(100% + 250px);
    }
    @media #{$huge} {
      height: calc(100% + 300px);
    }
  }

  .button {
    margin-bottom: 10px;

    @media #{$medium} {
      margin-top: 30px;
      margin-bottom: 60px;
    }
    @media #{$large} {
      margin-bottom: 80px;
    }
  }
}
.accent-block__header--home {
  margin-top: 0px;

  @media #{$maxMedium} {
    margin-bottom: 15px;
    padding-left: 30px;
  }

  @media #{$medium} {
    max-width: 400px;
    margin-top: 75px;
  }
}

.accent-block__visual-wrap {
  position: relative;
  //z-index: -1;
}

.accent-block__scroll-wrap {
  display: none;

  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  margin-bottom: -50px;
  @media #{$medium} {
    display: block;
    margin-left: -20px;
  }
  @media #{$larger} {
    margin-left: 0;
  }
  .rotate-container {
    position: relative;
    //overflow: hidden;
  }

  .rotate-container__content {
    transform: translate(0, 0);
    position: absolute;
  }
}

.scroll-line {
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 13px;

  &:before {
    content: "";
    display: inline-block;
    background-image: url("../images/little-arrow.svg");
    width: 10px;
    height: 10px;
    background-size: contain;
    transform: rotate(90deg);
    margin-right: 10px;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 37px;
    height: 1px;
    background-color: $black;
    margin-left: 10px;
  }
}

.product-collection-overview {
  > * {
    display: block;
  }
}

.wrap-visual-cta {
  @media #{$medium} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.products-collection__body--home {
  @media #{$medium} {
    max-width: 500px;
  }
}

.products-collection-visual-wrap {
  overflow: hidden;
  position: relative;

  text-decoration: none;
  margin-bottom: 20px;
  transition: $transitionDefault;

  @media #{$medium} {
    margin-bottom: 0;
  }

  a {
    display: block;

    @media #{$medium} {
      // height: 100%;
    }
    &:hover,
    &:focus,
    &:active {
      .products-collection-visual__title--left {
        @media #{$medium} {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
      .products-collection-visual__title--right {
        @media #{$medium} {
          padding-right: 40px;
        }
        @media #{$larger} {
          padding-right: 60px;
        }
      }
      .products-collection-visual__title--right-bottom {
        //orig 15px
        @media #{$small} {
          max-width: calc(200px + 25px);
        }

        @media #{$medium} {
          padding-right: 40px;
        }

        @media #{$large} {
          max-width: calc(260px + 25px);
        }
        //orig 35px
        @media #{$larger} {
          padding-right: 60px;
          max-width: calc(300px + 25px);
        }

        @media #{$huge} {
          max-width: calc(380px + 25px);
        }

        @media #{$massive} {
          max-width: none;
        }
      }

      .products-collection-visual-wrap__visual {
        @media #{$small} {
          transform: scale(1.1);
        }
      }
    }
  }
}

.blog-slideshow-wrap {
  position: relative;

  text-decoration: none;
  margin-bottom: 20px;
  transition: $transitionDefault;

  @media #{$medium} {
    margin-bottom: 0;
    height: 450px;
  }

  @media #{$large} {
    padding-left: 60px;
  }

  @media #{$larger} {
    height: 500px;
  }

  p {
    max-height: 120px;
  }

  a {
    display: block;

    @media #{$medium} {
      height: 100%;
    }
  }
}

.blog-slideshow-wrap__item {
  @extend .grid;
  overflow: hidden;
  position: relative;

  text-decoration: none;
  margin-bottom: 20px;
  transition: $transitionDefault;

  @media #{$medium} {
    margin-bottom: 0;
    height: 450px;
  }

  a {
    display: block;
    height: 100%;
    height: calc(100% - 20px);
  }
}

.products-collection-visual-wrap--preview {
  height: 125px;
}

.products-collection-visual-wrap__visual {
  height: 0;
  padding-bottom: 56%;
  @media #{$medium} {
    // height: 300px;
    width: 100%;
    padding-bottom: 63%;
  }

  transition: $transitionDefault;
}
.news-article {
  .products-collection-visual__title--blog {
    position: static;
  }
}
.products-collection-visual-wrap__visual--preview {
  width: 50%;
}

.products-collection-visual__title {
  //margin-bottom: 0;

  margin-right: 0;
  right: 0;
  //padding-right: 40px;
  background-color: $white;
  transition: $transitionDefault;
  margin-top: 10px;
  margin-bottom: 0px;
  @media #{$small} {
    position: absolute;
    bottom: 0;
    padding: 15px;
    max-width: 200px;
    margin-top: 0;
  }

  @media #{$large} {
    padding: 20px;
    margin-top: 55px;
    top: 0;
    bottom: auto;
    //todo: hoverstate max width aanpassen adv deze width + padding
    max-width: 260px;

    /*&:hover {
      padding-right: 60px;
    }*/
  }
  @media #{$larger} {
    padding: 35px;
  }
}

.products-collection-visual__title--left {
  left: 0;
  right: auto;
}
.products-collection-visual__title--right {
  @media #{$medium} {
    right: 0;
    left: auto;
  }
}

.products-collection-visual__title--right-bottom {
  @media #{$large} {
    margin-bottom: 55px;
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;
  }

  @media #{$larger} {
    max-width: 300px;
  }

  @media #{$huge} {
    max-width: 380px;
  }

  @media #{$massive} {
    max-width: none;
  }
}

/*.products-collection-visual__title--left {

  padding-left: 0;
  @media #{$medium} {
    padding-left: 20px;
  }


}*/

.products-collection-visual__title--preview {
  margin-top: 20px;
  padding: 3px;
  max-width: 250px;

  &:hover {
    margin-right: 10px;
    padding-right: 3px;
  }
}

.small--visualbg-right {
  .visual-bg {
    background-position-x: left;
    @media #{$medium} {
      //   background-position-x: center;
    }
  }
}

/* =================================================
   Products
   ================================================= */

.product-grid {
  display: flex;
  flex-wrap: wrap;

  @media #{$medium} {
    margin-bottom: 60px;
  }

  > .grid__item {
    margin-bottom: 20px;
    @media #{$medium} {
      margin-bottom: 40px;
    }

    &:before {
      content: "";
      float: left;

      @media #{$small} {
        padding-top: 100%;
      }
    }
  }
}

.product-grid-item-wrap {
  position: relative;
  border: 1px solid $grayLight;
  text-align: center;
  width: 100%;

  a {
    width: 100%;
    display: block;
    height: 0;
    padding-bottom: 100%;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }

  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    background-color: $accent;
    transition: $transitionDefault;
    pointer-events: none;
  }

  &:before {
    content: "\e912";
    @include iconfont;
    position: absolute;
    bottom: -20px;
    transform: rotate(180deg);
    margin-bottom: 25px;
    @include fs(8);
    z-index: 11;
    right: 20px;
    opacity: 0;
    transition: $transitionDefault;
    pointer-events: none;
  }

  @media #{$medium} {
    &:hover {
      &:after {
        height: 50%;
      }

      &:before {
        transform: rotate(180deg) translateY(20px);
        opacity: 1;
      }
    }
  }
}

.product-grid-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$medium} {
    display: flex;
    flex-flow: column;
  }

  .product-grid-item__img-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    @media #{$medium} {
      transform: translate(-50%, -50%);
      flex-grow: 1;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -10px;
    }
    width: 100%;
    overflow: hidden;
    text-align: center;

    @media #{$large} {
      margin-top: 0;
    }
    @media #{$large} {
      margin-top: -20px;
    }
  }

  img {
    padding: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
    max-height: 200px;

    @include object-fit(contain);

    @media #{$small} {
      max-height: 140px;
    }
    @media #{$medium} {
      max-width: 300px;
      max-height: 200px;
    }

    @media #{$large} {
      padding: 20px;
      max-height: 150px;
    }

    @media #{$larger} {
      padding: 0px;
      padding-top: 15px;
      max-height: 200px;
    }

    @media #{$massive} {
      max-height: 250px;
    }
  }
}

.product-grid-item--cheese {
  .product-grid-item__img-wrap {
    img {
      @media #{$small} {
      }
      @media #{$medium} {
        max-height: 350px;
      }

      @media #{$large} {
        max-height: 200px;
      }

      @media #{$larger} {
        padding-top: 30px;
        max-height: 250px;
      }

      @media #{$huge} {
        max-height: none;
      }
    }
  }
}

.product-grid-item__title {
  padding: 20px;
  align-self: flex-end;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-align: cener;
  margin-left: 0;
  @include worksans;
  @include fs(20);
  margin-top: auto;

  @media #{$small} {
    @include fs(20);
  }

  &:after {
    text-align: right;
    content: "\e912";
    @include iconfont;
    display: inline-block;
    transform: rotate(180deg);
    margin-top: 5px;
    @include fs(7);
    margin-left: 20px;
    @media #{$small} {
      @include fs(6);
    }
    @media #{$medium} {
      content: none;
    }
  }

  @media #{$medium} {
    display: block;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 5px;
    margin-left: 5px;
    @include fs(25);
  }

  @media #{$large} {
    @include fs(20);
  }

  @media #{$wide} {
    @include fs(25);
  }
}

.catalogus-cta {
  padding: 30px;
  background-color: $accent;
  margin-bottom: 20px;
  margin-top: 30px;

  @media #{$medium} {
    margin-bottom: 0;
    padding: 40px;
  }

  > span {
    display: block;
  }

  .floating-label--container {
    display: block;

    @media #{$medium} {
      display: inline-block;
      width: 100%;
    }

    @media #{$large} {
      width: auto;
      margin-right: 20px;
      flex-grow: 1;
    }
    @media #{$huge} {
      margin-left: 20px;
    }
  }

  ::placeholder {
    font-style: normal;
    text-transform: lowercase;
  }

  input[type="email"] {
    background: transparent;
    padding-left: 15px;
    border: 1px solid $black;
  }
}

.catalogus-cta__form {
  @media #{$medium} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  @media #{$larger} {
  }
  input[type="email"] {
    height: 50px;

    @media #{$medium} {
      width: 100%;
    }

    @media #{$large} {
      width: 100%;
      margin-bottom: 0;
    }

    @media #{$huge} {
      width: 100%;
    }
    @media #{$massive} {
      width: 100%;
    }
  }

  span,
  input[type="email"] {
    margin-bottom: 20px;
    @media #{$large} {
      margin-bottom: 0;
    }
  }

  span {
    display: block;
    margin-bottom: 20px;
    @media #{$medium} {
      display: inline;
      margin-bottom: 10px;
      width: 100%;
    }

    @media #{$huge} {
      width: auto;
      margin-bottom: 0;
    }
  }

  button {
    margin: 0;
    @media #{$medium} {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media #{$large} {
      margin-left: auto;
    }

    @media #{$massive} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.product-group-banners {
  margin-bottom: 0px;
  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.product-group-banners__banner {
  position: relative;
  margin-bottom: 0;

  @media #{$medium} {
    margin-bottom: 20px;
  }
  a {
    overflow: hidden;

    width: 100%;
    display: block;
    @media #{$large} {
      height: 200px;
    }
    &:hover,
    &:focus,
    &:active {
      .product-group-banners__visual-bg {
        transform: scale(1.1);
      }
    }
  }
}
.product-group-banners__visual-bg {
  height: auto;

  transition: $transitionDefault;
  @media #{$large} {
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
  }
}

.product-group-banners__banner__title {
  padding-top: 15px;
  padding-right: 15px;

  background-color: $white;
  margin-bottom: 0;
  max-width: 190px;

  .h3 {
    margin-bottom: 0;
  }
  transform: translateY(-35px);

  @media #{$small} {
    max-width: 300px;
  }
  @media #{$medium} {
    padding-left: 20px;
    max-width: 90%;
    position: absolute;

    left: 0;
    bottom: 0;
    padding-top: 20px;
    padding-right: 20px;
    transform: none;
  }
}

/* =================================================
   Product Detail
   ================================================= */

.product-detail {
  .h3 {
    margin-bottom: 5px;
    margin-top: 20px;
  }
}

.product-box {
  position: relative;
  padding: 0;

  @media #{$medium} {
    height: 0;
    padding-bottom: 100%;
  }

  .visual-bg {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;

    img {
      visibility: hidden;
    }
    @media #{$medium} {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:after {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 100%;
    background-color: $accent;
    height: 280px;
    transform: translate(-50%, -45%);
    height: 65%;
  }
}

.product-box-types {
  list-style: none;
  margin-bottom: 40px;
  @include fs(13);
  @media #{$small} {
    @include fs(15);
  }
  a {
    text-align: center;
    width: 100%;
    padding: 15px 5px;
    white-space: normal;

    &.active {
      background-color: $black;
      color: $white;
    }

    @media #{$medium} {
      padding: 15px 25px;
    }

    @media #{$larger} {
      padding: 15px 40px;
    }
  }

  .large--one-quarter {
    @media #{$large} {
      width: 33.33%;
    }
  }
}

.product-box-inside {
  > .grid__item {
    margin-bottom: 20px;
  }
}

@media #{$medium} {
  .medium--flex {
    display: flex;
  }

  .medium--flex-end {
    align-self: flex-end;
  }
}

/* =================================================
   About
   ================================================= */
.product-with-prize {
  position: relative;

  @media #{$medium} {
    margin-bottom: 0;
  }
  > img {
    position: absolute;
    right: 0;
    bottom: 0;

    margin-right: 35px;
    margin-bottom: -20px;

    width: 75px;

    @media #{$medium} {
      margin-bottom: 15px;
      top: 0;
      bottom: auto;
    }

    @media #{$larger} {
      width: 125px;
      margin-top: -30px;
      margin-bottom: 25px;
      margin-right: -20px;
    }
  }
}

/* =================================================
   BLOG
   ================================================= */
.blog-grid {
  list-style: none;
  margin-left: 0;
}
.news-article {
  .blog-grid-item__title {
    background-color: $white;
    color: $black;

    @media #{$medium} {
      margin-left: -200px;
      padding-right: 50px;
    }
    @media #{$larger} {
      padding-right: 150px;
      margin-left: -300px;
    }
  }
}

.blog-grid-item {
  position: relative;
  margin-bottom: 35px;

  @media #{$medium} {
    margin-bottom: 50px;
  }

  @media #{$larger} {
    margin-bottom: 75px;
  }

  .medium--equal-h {
    @media #{$medium} {
    }
  }

  &:nth-child(odd) {
    .blog-grid-item__title {
      @media #{$medium} {
        margin-left: -200px;
        padding-right: 50px;
      }
      @media #{$larger} {
        padding-right: 150px;
        margin-left: -300px;
      }
    }

    .blog-grid-item__link {
      // &:hover,
      // &:focus,
      // &:active {
      //   .blog-grid-item__title {
      //     @media #{$medium} {
      //       left: -50px;
      //     }
      //     @media #{$larger} {
      //       left: -100px;
      //     }
      //   }
      // }
    }
  }

  &:nth-child(even) {
    .article-teaser .grid {
      @media #{$medium} {
        justify-content: flex-end;
      }
    }
    .blog-grid-item__visual-item {
      order: 2;
    }
    .blog-grid-item__info-item {
      text-align: left;
      order: 1;
      @media #{$medium} {
        justify-content: flex-end;
      }
    }
    .blog-grid-item__title {
      @media #{$medium} {
        margin-right: -300px;
        padding-right: 50px;
        padding-left: 0;
      }
      @media #{$larger} {
        margin-right: -200px;
        padding-right: 100px;
        margin-left: 0px;
      }
    }

    // .blog-grid-item__link {
    //   &:hover,
    //   &:focus,
    //   &:active {
    //     .blog-grid-item__title {
    //       @media #{$medium} {
    //         left: 50px;
    //       }
    //       @media #{$larger} {
    //         left: 100px;
    //       }
    //     }
    //   }
    // }
  }
}
.blog-grid-item__link {
  display: block;

  &:hover,
  &:focus,
  &:active {
    p {
      color: $black;
    }

    .blog-grid-item__visual-wrap {
      .blog-grid-item__visual {
        transform: scale(1.1);
      }
    }
  }
}
.blog-grid-item__visual-wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.blog-grid-item__visual {
  width: 100%;
  transition: $transitionDefault;

  @media #{$medium} {
    height: auto;
  }

  img {
    display: none;
  }
}

.blog-grid-item__info-item > div {
  @media #{$medium} {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.blog-grid-item__body {
  margin-bottom: 0;

  @media #{$medium} {
    width: 325px;
  }

  @media #{$huge} {
    max-width: 325px;
  }
}

.blog-grid-item__title,
.blog-grid-item__body {
  @media #{$medium} {
    width: auto;
  }
}

.blog-grid-item__title {
  transition: $transitionDefault;
  background: $white;
  color: $black;
  padding: 20px;
  padding-left: 0;
  z-index: 5;
  position: relative;
  left: 0;
  max-width: 650px;
  margin-top: -40px;
  width: 90%;

  @media #{$medium} {
    width: auto;
    padding: 35px;
    margin-top: 25px;
  }

  @media #{$larger} {
    margin-top: 50px;
  }
}

.blog-grid-item__content {
  @media #{$medium} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    .blog-grid-item__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      flex-wrap: wrap;
    }

    .blog-grid-item__body,
    .blog-grid-item__body__top {
      flex: 1 1 auto;
      flex-shrink: 1;
      max-width: 100%;
    }
  }
}

/* =================================================
   Blog detail
   ================================================= */

.blog-detail__image-wrap {
  @media #{$medium} {
    position: absolute;
    left: 0;
  }
}
.blog-detail__image {
  width: 100%;
  @media #{$medium} {
    height: 100%;
  }
  img {
    width: 100%;
  }
}

.blog-detail__body {
  h3 {
    @include fs(16);
    @include lh(18);
    @media #{$medium} {
      @include fs(18);
      @include lh(20);
    }
  }
  h4 {
    @include fs(16);
  }
}
.blog-detail__info {
  position: relative;
  z-index: 11;
  background-color: $white;
  padding: 0px;

  left: 0;

  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;

  @media #{$medium} {
    width: auto;
    // width: 90%;
    margin-left: -100px;
    padding: 40px;
    margin-top: 150px;
    padding-bottom: 10px;
  }
  @media #{$large} {
    padding: 80px;
    padding-bottom: 0px;
    margin-top: 250px;
  }
}

.pagination {
  text-align: center;
  margin-bottom: 20px;

  a {
    margin: 5px 10px;
    &.active,
    &:hover,
    &:focus,
    &:active {
      font-weight: 600;
    }
  }
}

.overflow-v.overflow-v {
  overflow: visible;
}

/* =================================================
   ABOUT -- HISTORY
   ================================================= */

.timeline-container {
  width: 100%;
  position: relative;
  display: none;
  @media #{$medium} {
    display: block;
  }
  &:after {
    @media #{$medium} {
      content: "";
    }
    position: absolute;
    top: 0;

    width: 100%;
    height: 1px;
    background-color: #000;
    z-index: -2;
  }
}

.timeline {
  list-style: none;
  margin-left: 0;
  overflow: hidden;

  @include clearfix;

  @media #{$medium} {
    overflow: visible;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  li {
    display: inline-block;
    float: left;

    &:first-child {
      margin-left: 0;
    }
  }
}

.timeline__event__button {
  background: transparent;
  border: none;
  padding: 0px;
  margin-top: 20px;
  overflow: visible;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    border: none;
  }

  &:after {
    content: none;
  }
}

.timeline__event__circle {
  width: 100%;
  height: 50px;
  display: block;
  position: relative;
  border: none;
  padding: 0 30px;

  @media #{$medium} {
    width: 30px;
    height: 30px;
  }

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $black;
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    margin-top: 5px;
  }
  &:after {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    content: "";
    display: block;
    background-color: $white;
    border: 5px solid $black;
    width: 20px;
    height: 20px;
    transition: $transitionDefault;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    margin-top: -17.5px;

    @media #{$medium} {
      margin-top: -7.5px;
    }
  }
}
.timeline__event__year {
  float: left;
  @include fs(18);
  @include harmansimple;
  transition: $transitionDefault;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;

  @media #{$medium} {
    margin-top: 40px;
    margin-left: 0;
  }
}

.timeline__event__button:hover,
.timeline__event__button:focus,
.timeline__event__button:active,
.timeline__event__button--active {
  outline: none;
  box-shadow: none;

  .timeline__event__circle:after {
    width: 30px;
    height: 30px;
  }

  .timeline__event__year {
    @include fs(25);
    @media #{$medium} {
      @include fs(35);
    }
  }
}

#geschiedenis > h2 {
  @media #{$maxMedium} {
    margin-bottom: -30px;
  }
}

.history-wrap {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 0;

  @media #{$medium} {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}

.grid-item__history-img {
  @media #{$maxMedium} {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.history-item__info {
  p {
    @include clearfix;
    display: inline-block;
    vertical-align: middle;
  }

  img {
    @include object-fit(contain);
    height: auto;
  }
}
.history-item__year {
  @include harmansimple;
  margin-bottom: 10px;
  display: block;
  @include fs(16);

  @media #{$medium} {
    @include fs(18);
    display: none;
  }
}

.history-item {
  @media #{$medium} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.history-item__visual {
  margin-bottom: 10px;
  width: 100%;
  @media #{$medium} {
    margin-bottom: 0;
  }
}

.history-item__visual-bg {
  height: auto;

  @media #{$medium} {
    height: 450px;
  }
}

.history-item__link-pager {
  display: block;
  @include clearfix;
  width: 100%;
  text-align: center;
  margin-top: 0;

  @media #{$medium} {
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    width: 60px;
    margin-top: 10px;

    position: absolute;
    bottom: 0;
    margin-bottom: -20px;

    &:after {
      content: none;
    }
    &:hover,
    &:focus,
    &:active {
      i {
        margin-left: 20px;
      }
    }
  }

  i {
    display: none;
    @include fs(10);
    transform: scale(-1);

    float: left;
    transition: $transitionDefault;
    @media #{$medium} {
      display: block;
    }
  }
  span {
    text-align: center;
    &:before {
      content: "Bekijk ";
      display: inline-block;
      margin-right: 5px;
      @media #{$medium} {
        content: none;
      }
    }
    @media #{$medium} {
      text-align: left;
      display: block;
      @include cormorant;
      margin-left: 10px;
    }

    margin-top: 20px;
    @include fs(16);
  }
}

.history-item__link-prev {
  display: block;
  margin-top: 10px;
  padding: 10px;
  @include fs(13);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media #{$medium} {
    display: none;
  }
}

html[lang="nl"] {
  .history-item__link-pager {
    span {
      &:before {
        content: "Bekijk ";
        @media #{$medium} {
          content: none;
        }
      }
    }
  }
}
html[lang="en"] {
  .history-item__link-pager {
    span {
      &:before {
        content: "View ";
        @media #{$medium} {
          content: none;
        }
      }
    }
  }
}

/* =================================================
   Contact
   ================================================= */

.contact-visual {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 0;
  padding-bottom: 53%;

  margin-top: 60px;

  @media #{$medium} {
    margin-top: 0;
    height: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.contact-visual__title {
  padding: 10px;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: -60px;

  @media #{$medium} {
    margin-left: 0;
    left: auto;
    right: 0;
    margin-top: 65px;
    padding: 30px 40px;
  }
}

.contact-form {
  @media #{$medium} {
    margin-top: 50px;
  }

  @media #{$larger} {
    margin-top: 100px;
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 20px;

      @media #{$medium} {
        margin-bottom: 10px;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
  textarea {
    height: 100px;
    @media #{$medium} {
      margin-bottom: 40px;
    }
  }

  button {
    margin-bottom: 0;
  }
}

/* =================================================
   News articles
   ================================================= */

.blog-slideshow__info-wrap {
  @media #{$medium} {
    max-width: 400px;
  }
}

/* =================================================
   PAGER BLOG ITEMS - HOME & DETAIL BLOG
   ================================================= */

.blog-pager {
  list-style: none;
  text-align: center;
  margin-left: 0;

  &.blog-pager--blog-detail {
    @media #{$small} {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: -20px;
    }
    @media #{$medium} {
      margin-left: 0;
    }

    .blog-pager__item {
      &:not(.blog-pager__item--back) {
        width: 100%;

        @media #{$medium} {
          width: auto;
        }
      }
    }
  }
}

.blog-pager__item {
  @include clearfix;
  position: relative;
  display: inline-block;
  margin-top: 20px;

  &:not(.blog-pager__item--back) {
    width: 100%;

    @media #{$small} {
      width: 50%;
    }
    @media #{$medium} {
      width: auto;
    }
  }

  a {
    display: block;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

.blog-pager__item--back.blog-pager__item--back {
  clear: left;
  display: block;
  text-align: center;

  @media #{$medium} {
    float: right;
    clear: none;
    text-align: right;
  }
  .blog-pager__item__arrow-wrap {
    width: auto;

    i,
    span {
      display: inline-block;
      @media #{$medium} {
        display: block;
      }
    }
  }
}

.blog-pager--blog-detail {
  .blog-pager__item--prev {
    .blog-pager__item__arrow-wrap {
      i {
        margin-right: 50px;
      }
    }
  }
  .blog-pager__item--next {
    .blog-pager__item__arrow-wrap {
      i {
        margin-left: 40px;
      }
    }
  }
}

.blog-pager__item__arrow-wrap {
  width: 250px;
  display: none;
  @media #{$desktop} {
    display: block;
  }
  @include clearfix;
  i {
    transition: $transitionDefault;
    display: block;
    margin-bottom: 5px;
    @include fs(8);
  }

  span {
    margin-bottom: 25px;
    display: block;
  }
}
.blog-pager__item__arrow-wrap--home {
  width: auto;
}
.blog-pager__item--prev {
  text-align: left;
  span {
    margin-left: 10px;
  }
}
.blog-pager__item--next {
  i {
    transform: rotate(180deg) translate(0, 0);
    float: left;
  }
  span {
    clear: left;
  }
}

.blog-pager__item__content {
  clear: both;
  position: relative;

  @media #{$small} {
    padding-left: 20px;
  }
  @media #{$medium} {
    padding-left: 0;
    width: 300px;
  }
}

.blog-pager__item__visual {
  height: 170px;
  width: 100%;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  @media #{$large} {
    width: 250px;
  }
}

.blog-pager__item__header {
  position: relative;
  text-align: left;
  display: block;
  margin-left: auto;
  z-index: 11;
  margin-right: auto;
  padding: 15px 20px;
  background-color: $white;
  width: 80%;
  margin-top: -40px;
  cursor: pointer;
  @include harmansimple;

  @media #{$large} {
    max-height: 120px;
    position: absolute;
    left: 0;
    margin-left: 150px;
    top: 0;
    margin-top: 30px;
    //right: 0;
    width: 250px;
    display: inline-block;
  }
}

.blog-pager__item__header--home {
  @media #{$large} {
    width: 150px;
  }
  @media #{$larger} {
    width: 200px;
  }
  @media #{$wide} {
    width: 250px;
    margin-right: 40px;
  }
}

.blog-pager--blog-detail {
  .blog-pager__item--prev {
    float: left;

    .blog-pager__item__arrow-wrap {
      text-align: right;
      float: right;

      @media #{$medium} {
        margin-right: 50px;
      }
      i {
        margin-left: 20px;
      }
    }
  }

  .blog-pager__item--next {
    @media #{$large} {
      margin-right: 150px;
    }
    .blog-pager__item__arrow-wrap {
      text-align: left;
      margin-left: 30px;
      i {
        margin-left: 20px;
      }
    }
  }
}

//news nav is blog slider nav

.news-nav--prev {
  float: left;
}
.news-nav--next {
  float: right;

  @media #{$medium} {
    margin-right: 60px;
  }
  @media #{$larger} {
    margin-right: 0;
  }

  i {
    float: none;
    @media #{$medium} {
      margin-left: 30px;
    }
  }
}
html[lang="en"] {
  .news-nav--next {
    i {
      @media #{$medium} {
        margin-left: 30px;
      }
    }
  }
}
html[lang="nl"] {
  .news-nav--next {
    i {
      @media #{$medium} {
        margin-left: 50px;
      }
    }
  }
}

.news-nav__item {
  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.news-nav {
  position: relative;
  z-index: 12;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  @include clearfix;

  user-select: none;
  text-align: center;
  margin-top: 20px;
  float: left;

  .news-nav__centered-button {
    padding-left: 20px;
    @media #{$medium} {
      padding-left: 0;
    }
  }

  .blog-pager__item {
    cursor: pointer;
  }

  @media #{$medium} {
    // display: block;
    margin-left: 20px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$large} {
    display: block;
  }

  @media #{$larger} {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    float: none;
    position: absolute;
    margin-bottom: 10px;
    bottom: 0;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    width: calc(100% + 135px);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -15px;
  }

  @media #{$wide} {
    width: calc(100% + 135px);
    margin-left: -15px;
  }

  @media #{$huge} {
    margin-bottom: 10px;
  }

  .blog-pager__item__arrow-wrap {
    @media #{$medium} {
      display: inline-block;
    }
  }

  @media #{$massive} {
    margin-top: -10px;
  }

  .blog-pager__item {
    margin-top: 0px;

    span {
      margin-bottom: 0;
    }
  }
}

.news-nav__centered-button {
  @media #{$medium} {
    display: inline-block;
    align-self: center;
    margin-top: 0;
    margin-bottom: 10px;
  }

  @media #{$large} {
    display: none;
    margin-bottom: 0;
  }
}

.news-article-wrapper {
  overflow: visible;
  max-width: 1200px;
  z-index: 9;

  @media #{$medium} {
    width: 80%;
  }
  @media #{$larger} {
    width: 85%;
  }
  @media #{$massive} {
    width: 90%;
  }

  .h4 {
    @media #{$medium} {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -30px;
    }
  }
}

.news-article-wrapper,
.h4 {
  margin-left: auto;
  margin-right: auto;
}

.news-article {
  width: 100%;
  height: 100%;
  top: 0;
  left: 40px;
  transition: all 0.7s linear;
  opacity: 0;
  z-index: 0;
  height: 0;

  @media #{$medium} {
    position: absolute;
    height: 100%;
  }

  p {
    @media #{$medium} {
      max-height: 140px;
      margin-bottom: 20px;
    }
  }
}

.js-news-article--show {
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 1;
  height: auto;
}

.js-news-article--viewed-left,
.js-news-article--viewed-right {
  transition: all 0.3s;
}
.js-news-article--viewed-left {
  left: -250px;
}

.js-news-article--viewed-right {
  left: 250px;
}

.news-article-previews {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 40px;
  display: none;
  margin-left: 0;

  @media #{$large} {
    display: block;
  }
}

.news-article-previews__slider {
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-out;
}

.news-article-previews__slider--no-animation {
  transition: none;
}

.news-article-preview {
  position: absolute;
  transition: all 0.7s ease-out;
  padding-right: 30px;
  margin-top: 0;
}

.news-article-preview--no-animation {
  transition: none;
}

.js-news-article-preview--active {
  opacity: 1;
  .blog-pager__item__content {
    opacity: 1;
  }
}

.location-grid {
  position: relative;

  .products-collection-visual__title {
    padding: 15px;
  }

  .products-collection-visual__title--left {
    top: auto;
    bottom: 0;
    margin-bottom: 55px;
  }
}

.location-info {
  margin-top: 75px;
  a {
    line-height: 1.5em;
  }
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
